import React from 'react';
import { Grid, useTheme, useMediaQuery, Box, CardActionArea } from '@material-ui/core';
import NavBarSocialMedia from '../../components/navBar/NavBarSocialMedia';
import HelperPopup from '../../components/login/helperPopup';
import exclamationImage from '../../assets/exclamation.svg';
import './../../styles/components/loanSuccessPage.scss';
import logoPlayStore from './../../assets/logoPlayStore.png';
import logoAppStore from './../../assets/logoAppStore.png';

const LoanRequestResultPage = (props) => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    const customMargin = smUp ? '15vh' : '10vh';

    return (
        <>
            <NavBarSocialMedia></NavBarSocialMedia>
            <Box className="assetBg"></Box>
            <Box style={{ marginTop: customMargin }}></Box>
            <Grid item container>
                <Grid item container xs={1} className=""></Grid>
                <Grid item container xs={10}>
                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <p className={`color-light-blue fw-400 fs-${smUp ? '24' : '18'}`}>{props.headerLead}</p>
                            <Box className="mt-1"></Box>
                            <p className={`color-primary fs-${smUp ? '38 fw-500' : '22 fw-700'}`}>{props.headerTitle}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="mt-1 "></Box>
                            <p className={`mb-3 color-primary fs-${smUp ? '38 fw-500' : '22 fw-700'}`}>{props.headerSubtitle}</p>
                        </Grid>

                        <Grid item container xs={12} sm={10} md={6} className="">
                            <Grid item container xs={12} justifyContent="center" alignItems="flex-start">
                                <Box className="mb-3"> {props.resultImage} </Box>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} className="">
                            <div className="box">
                                <img src={exclamationImage} width="35px" alt="" />
                                <HelperPopup
                                    helperText="¿Cómo accedo al dinero?
                                    Clickeá aquí para más info."
                                    dialogAction="Entendido"
                                >
                                        <Grid container>
                                            <Grid item xs={12}>
                                            <h3 className="h2">
                                                Para usar tu dinero tenés que acceder a tu cuenta por alguno de estos medios:
                                            </h3>
                                            </Grid>
                                            <Grid item container xs={12}>
                                                <Grid item xs={12}>
                                                    <h2 className="h3">
                                                    Descargá la App Macro
                                                    </h2>
                                                    <p className="p">
                                                    Con la app podrás usar tu dinero, consultar tu saldo y realizar pagos.
                                                    </p>
                                                </Grid>
                                                <Grid item container xs={12}>
                                                    <Grid item container xs={6} style={{ padding: '5px' }}>
                                                        <Grid item className="buttonStores">
                                                            <CardActionArea href="https://play.google.com/store/apps/details?id=ar.macro&hl=es_AR&gl=US">
                                                                <div className="flex-row">
                                                                    <img src={logoPlayStore} alt="" />
                                                                    Descargar en Google
                                                                </div>
                                                            </CardActionArea>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container xs={6} style={{ padding: '5px' }}>
                                                        <Grid item className="buttonStores">
                                                            <CardActionArea href="https://apps.apple.com/ar/app/macro/id1173611617">
                                                                <div className="flex-row">
                                                                    <img src={logoAppStore} alt="" />
                                                                    Descargar en AppStore
                                                                </div>
                                                            </CardActionArea>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <h2 className="h3">
                                                    Acercate a una sucursal
                                                </h2>
                                                <p className="p">
                                                    Podés acercarte a cualquier sucursal. Ahorrá tiempo y <a href="https://agenda.macro.com.ar/new?organization=5e8c9c11aacca63c310d4548">sacá un turno.</a>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <h2 className="h3">
                                                    Entrá al Home Banking
                                                </h2>
                                                <p className="p">
                                                    Si ya sos cliente podés acceder al Home Banking y empezar a usar tu préstamo.
                                                </p>
                                            </Grid>
                                            <Box m={1} />
                                        </Grid>
                                </HelperPopup>
                            </div>
                        </Grid>

                        <Box m={1} />

                        <Grid item container xs={12} sm={10} md={6} className="">
                            <Grid item xs={12} className="mt-2">
                                <p className={`color-light-blue fw-400 fs-${smUp ? '24' : '18'}`}>{props.title}</p>
                            </Grid>

                            {props.children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="mt-4-landscape"></div>
        </>
    );
};

export default LoanRequestResultPage;
