import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { Grid } from '@material-ui/core';

export const QuestionSlider = (props) => {
    const { questions, onQuestionChange, onQuestionsAnswered } = props;
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const handleNextQuestion = (event) => {
        event.preventDefault();
        if (currentQuestionIndex === questions.length - 1) {
            onQuestionsAnswered();
        } else {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const isQuestionAnswered = () => {
        if (questions[currentQuestionIndex].selectedOptionId === null) {
            return false;
        }
        return true;
    };

    return (
        <>
            {questions && (
                <>
                    <Grid container direction="column" justifyContent="center" alignItems="center" lg={12} sm={12} xs={12} >
                        <Grid item lg={4}></Grid>

                        <Grid item lg={8} >
                            <MultipleChoiceQuestion question={questions[currentQuestionIndex]} onChange={onQuestionChange} />
                        </Grid>

                        <div className="mt-5"></div>
                        <Grid container direction="column" justifyContent="center" alignItems="center" xs={12} sm={4} >
                            
                            
                                <Button
                                    style={{ width: '100%' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNextQuestion}
                                    disabled={!isQuestionAnswered()}>
                                    Siguiente
                                </Button>
                           
                            
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
