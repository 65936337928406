import React, { useState, useEffect, forwardRef } from 'react';
import { Snackbar, Slide} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Toaster = (props) => {
    const [open, setOpen] = useState(props.open);
    const { textToShow, autoHideDuration, type, handleToasterClose } = props;

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
    });

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        if (handleToasterClose) {
            handleToasterClose();
        }
    };

    const TransitionComponent = (props) => {
        return <Slide {...props} direction="up" />;
    };    

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration || 6000}
            onClose={handleClose}
            TransitionComponent={TransitionComponent}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity={type || 'info'}>
                {textToShow}
            </Alert>
        </Snackbar>
    );
};

export default Toaster;
