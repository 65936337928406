import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import documentService from '../services/document.service';
import useCurrentTask from '../components/bpm/useCurrentTask';
import '../styles/components/documentsUpload.scss';
import MacroStepPage from './shared/MacroStepPage';
import loanRequestService from '../services/loanRequest.service';
import eventTrackingService from '../services/eventtracking.service';
import { ManualDocumentUploader } from '../components/documentsUpload/ManualDocumentUploader';
import { useTranslation } from 'react-i18next';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import frontDni from './../assets/sampleFrontDni.svg';
import Loader from './../components/loader/Loader';
import DocumentsUploadHints from './shared/DocumentsUploadHints';
import { ShowValidationError } from '../components/documentsUpload/DocumentUploaderCommon';
import { DocumentsUpload } from './documentsUpload';
import SteppersImage from './shared/SteppersImage';

export const DocumentsUploadFront = (props) => {
    const { hashKey } = props.match.params;
    const [loanRequest, setLoanRequest] = useState(undefined);
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [activateNext, setActivateNext] = useState(true);
    const [documentsUploaded, setDocumentsUploaded] = useState(false);
    const [documents, setDocuments] = useState(undefined);
    const [imgSrc, setImgSrc] = useState(null);
    const [uploadMethod, setUploadMethod] = useState(null);
    const [isPreview, setIsPreview] = useState(true);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const analyticsStep = 6;

    const task = useCurrentTask(hashKey, props.taskDefinitionKey);

    async function getLoanRequest() {
        try {
            setLoading(true);
            setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getLoanRequest();
    }, [hashKey]);

    useEffect(() => {
        async function getDocuments() {
            try {
                setLoading(true);
                setDocuments((await documentService.getDocuments(hashKey, 'documentsUpload')).data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        if (loanRequest) {
            setDocumentsUploaded(loanRequest.documentsCompleted);
            getDocuments();
        }
    }, [loanRequest]);

    const onDocumentUploaded = (imgSrcUploaded, uploadMethod) => {
        eventTrackingService.trackEvent('dni frente', uploadMethod, null, analyticsStep);
        setUploadMethod(uploadMethod);
        setImgSrc(imgSrcUploaded);
        setActivateNext(imgSrcUploaded ? true : false);
    };

    const handleNext = async () => {
        try {
            setLoading(true);
            eventTrackingService.trackEvent('dni frente completed', uploadMethod, null, analyticsStep);
            await task.completeTask();
        } catch (error) {
            setHasValidationErrors(true);
            if (error.response) {
                if (error.response.data) {
                    setErrorCode(error.response.data.errorCode);
                } else {
                    console.error('Error: ' + error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRestartFaceValidation = () => {
        setHasValidationErrors(false);
    };

    const hintDni = useMemo(DocumentsUploadHints, []);

    return (
        <>
            <Loader loading={loading} />
            <MacroStepPage
                headerLead="Estás muy cerca de tu préstamo"
                headerTitle={isPreview ? 'Vamos a validar tu identidad' : 'Sacale una foto a tu DNI'}
                hashKey={hashKey}
                task={task}
                backToText="Tipo DNI"
                taskDefinitionKey={props.taskDefinitionKey}
                hideStepper={true}
                stepNumber={1}
                totalSteps={4}
                className="mt-1">
                {isMobile ? (
                    loanRequest && documents && !hasValidationErrors ? (
                        isPreview ? (
                            <DocumentsUpload onHandleNext={setIsPreview} />
                        ) : (
                            <>
                                <SteppersImage elementsNumber={3} activeElement={1} />
                                <Grid item container style={{ zIndex: 100 }} xs={12} direction="row" justifyContent="center">
                                    <ManualDocumentUploader
                                        title="DNI frente"
                                        sampleSrc={frontDni}
                                        hintInfo={hintDni}
                                        hashKey={loanRequest.hashKey}
                                        document={documents[0]}
                                        onDocumentUploaded={onDocumentUploaded}
                                        onHandleNext={handleNext}
                                    />
                                </Grid>
                            </>
                        )
                    ) : (
                        hasValidationErrors && ShowValidationError(handleRestartFaceValidation, false)
                    )
                ) : (
                    <ContinueMobile hashKey={hashKey} />
                )}
            </MacroStepPage>
        </>
    );
};
