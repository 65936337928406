import React from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import identityValidationFailedImage from '../../assets/identity_validation_failed.svg';
import hintNoGlasses from '../../assets/hint_no_glasses.svg';
import hintRotateIdCardImage from '../../assets/hint_rotate_idcard.svg';
import hintCameraNoFlash from '../../assets/hint_camera_no_flash.svg';
import documentService from '../../services/document.service';
import eventTrackingService from '../../services/eventtracking.service';
import '../../styles/components/stepperValidation.scss';
import '../../styles/components/toc.scss';

export const useStyles = makeStyles((theme) => ({
    captureStyle: {
        width: '100%',
        height: 'auto',
        maxHeight: 'auto',
        borderRadius: '8px',
        marginLeft: '30px',
    },
    captureStylePortrait: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            maxHeight: '250px',
            maxWidth: '100%',
        },
    },
}));

export const uploadFile = async (translate, hashKey, file, b64CaptureFile, uploadMethod, token, document, setters) => {
    if (setters.setShowTOC) setters.setShowTOC(false);
    setters.setLoading(true);
    await documentService
        .uploadDocument(hashKey, document.name, token, file)
        .then(() => {
            setters.setImgSrc(b64CaptureFile);
            setters.onDocumentUploaded(document, uploadMethod);
            setters.setLoading(false);
        })
        .catch((error) => {
            setters.setImgSrc(null);
            setters.onDocumentUploaded(null);
            setters.setLoading(false);
            if (setters.setShowTOC) setters.setShowTOC(false);
            if (setters.setShowAlternativeCapture) setters.setShowAlternativeCapture(false);
            if (setters.clearTimeout) setters.clearTimeout();

            if (error.response.data) {
                const { errorCode } = error.response.data;

                switch (errorCode) {
                    case 'DOCUMENT_NUMBER_MISMATCH':
                        // Vamos a obviar la  si el documento no coincide.
                        setters.onDocumentUploaded(document);
                        return;

                    case 'DOCUMENT_CANNOT_READ_BARCODE':
                        // track the barcode not found event
                        eventTrackingService.trackEvevalidacionnt('Barcode_not_found', uploadMethod, null, 0);
                        setters.setMessage(translate(`BAR_CODE_VALIDATION_ERROR.DOCUMENT_CANNOT_READ_BARCODE.DESCRIPTION`));
                        break;

                    case 'DOCUMENT_INCORRECT_BARCODE':
                        setters.setMessage(translate(`BAR_CODE_VALIDATION_ERROR.DOCUMENT_INCORRECT_BARCODE.DESCRIPTION`));
                        break;

                    default:
                        setters.setMessage(translate(`RENAPER_VALIDATION_ERROR.${errorCode}.DESCRIPTION`));
                }
            } else {
                setters.setMessage(translate(`RENAPER_VALIDATION_ERROR.${error.response.status}.DESCRIPTION`));
            }
            setters.setOpen(true);
        });
};

export const ShowValidationError = (handleRestartFaceValidation, isSelfie) => {
    const styleTitle = { color: '#003057', lineHeight: '30px' };
    const styleText = { color: '#003057', lineHeight: '27px' };
    const styleImgHint = { padding: '5px', width: '30px' };
    const styleTextHint = { lineHeight: '24px', fontWeight: 400, color: '#212121' };

    return (
        <Grid container justifyContent="center">
            <Grid item>
                <Box className="pushDiv"></Box>
            </Grid>
            <Grid item xs={8}>
                <Box display="flex" justifyContent="center">
                    <img src={identityValidationFailedImage} alt="No pudimos validar tu identidad" className="mt-05"></img>
                </Box>
            </Grid>
            <Grid item xs={11}>
                <p className="text-align-center fs-24 fw-600" style={styleTitle}>
                    No pudimos validar tu identidad
                </p>
            </Grid>
            <Grid item xs={12}>
                <p className="text-align-center fs-16 fw-400 mt-05" style={styleText}>
                    Tené en cuenta los siguientes consejos para tomar la foto:
                </p>
            </Grid>
            <Grid item xs={11}>
                <Box>
                    <ul className="hint-list">
                        <li className="mt-05">
                            <img src={hintRotateIdCardImage} style={styleImgHint} alt="" />
                            <p style={styleTextHint}>Tomá la foto de forma vertical para poder obtener mejor los datos</p>
                        </li>
                        <li className="mt-05">
                            <img src={hintCameraNoFlash} style={styleImgHint} alt="" />
                            <p style={styleTextHint}>Evitá reflejos. Recomendamos no usar el flash.</p>
                        </li>
                        {isSelfie && (
                            <li className="mt-05">
                                <img src={hintNoGlasses} style={styleImgHint} alt="" />
                                <p style={styleTextHint}>Evitá el uso de accesorios que tapen tu rostro como gorros, lentes o barbijo.</p>
                            </li>
                        )}
                    </ul>
                </Box>
            </Grid>

            <Grid item xs={12} className="center mt-4">
                <Button className="width-100-per mt-4" onClick={handleRestartFaceValidation} variant="contained" color="primary">
                    <span className="fs-16 fw-400">
                        {!isSelfie && 'Volver a sacar las fotos'}
                        {isSelfie && 'Reintentar prueba de vida'}
                    </span>
                </Button>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '20px !important' }}>
                <br />
            </Grid>
        </Grid>
    );
};
