import React, { useState } from 'react';

import MacroPage from './shared/MacroPage';
import { Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import '../styles/components/error.scss';
import '../styles/components/validationIdentityPage.scss';
import '../styles/components/toc.scss';

import Toaster from '../components/alerts/Toaster';
import sadPc from '../assets/sadMan.svg';


export const OnBoardingNotAvailable = (props) => {
    const { history } = props;
    
    const { fromHour } = props;
    const { toHour } = props;


    const theme = useTheme();


    // const [captureImg, setCaptureImg] = useState(false);
    // const [message, setMessage] = useState('Se produjo un error');

    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const rejectedImage = <img src={sadPc} alt=""></img>;

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleBack = (event) => {
        event.preventDefault();
        history.push({ pathname: `/`, search: history.location.search });
    };

    
    return (<>
            <MacroPage>
                <Grid container direction="column" className="">
                    <Grid item xs={12}>
                        <Box className="pushDiv"></Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container direction="row" alignItems="center" justifyContent="center" className="validationContainer">
                            <Grid item container xs={10} direction="row" alignItems="center" className="">
                                <Grid item container xs={12} md={8} direction="column" className="">
                                    <Grid item xs={12}>
                                        <p className="fs-30 fw-400 ls-33 color-primary">No es posible iniciar la solicitud</p>
                                    </Grid>
                                    <Grid item className="mt-4" xs={12}>
                                        <Box className="mt-3"> </Box>
                                        <p className="mb-4 fs-16 color-dark-gray fw-400">
                                            Lo sentimos, no es posible iniciar una solicitud en este momento.
                                            <br />
                                            Por favor volvé a intentar entre las {props.match.params.fromHour}hs y las {props.match.params.toHour}hs.
                                            <br />
                                            <br />
                                            ¡Te esperamos!
                                            
                                        </p>
                                    
                                        {!smDown &&
                                    <Button className="width-80-per" variant="contained" color="primary" onClick={handleBack} >
                                    Volver
                                </Button>    
                                
                                }
                                    
                                    
                                    
                                    </Grid>



                                    
                                </Grid>

                                
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {smDown && (
                    <>
                    <Grid container direction="row" alignItems="center" justifyContent="center" className="mt-10">
                    <Grid item container alignItems="center" xs={10}>
                    <Box className="mt-3"></Box>
                    {rejectedImage}
                </Grid></Grid>
                    <Grid item xs={12}>
                        <div className="startButton center fixed-bottom-padding">
                            <Button className="flex center width-80-per" variant="contained" color="primary" onClick={handleBack} >
                                Volver
                            </Button>
                        </div>
                    </Grid></>
                )}


            </MacroPage>
        </>
    );

};
