import React, { useState, useEffect, useRef } from 'react';
import { Link, Grid, Button } from '@material-ui/core';
import TocAutocaptureSelfie from './TocAutocaptureSelfie';
import documentService from '../../services/document.service';
import '../../styles/components/stepperValidation.scss';
import Toaster from '../alerts/Toaster';
import { useTranslation } from 'react-i18next';
import '../../styles/components/toc.scss';
import cameraIcon from '../../assets/camera-icon.svg';
import Loader from '../loader/Loader';
import eventTrackingService from '../../services/eventtracking.service';
import { useStyles, uploadFile } from './DocumentUploaderCommon';

export const DocumentUploaderSelfie = (props) => {
    const { title, hintInfo, document, hashKey, onDocumentUploaded, onHandleNext, onGetToken, previousToken, sampleSrc } = props;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Se produjo un error');
    const [token, setToken] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(null);
    const [xsValue, setXsValue] = useState(12);
    const [showLiveness, setShowLiveness] = useState(false);
    const [showTOC, setShowTOC] = useState(false);

    const timeoutHandler = useRef();

    const getToken = async () => {
        try {
            setLoading(true);
            const newToken = (await documentService.getUploadToken(hashKey)).data;
            setToken(newToken);
            setImgSrc(null);
            onDocumentUploaded(null);

            onGetToken(newToken);
        } catch (error) {
            setMessage(error.response.data);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (previousToken) {
            setToken(previousToken);
        }
    }, []);

    useEffect(() => {
        if (document && !token && !previousToken) {
            getToken();
        }
    }, [document, document.name]);

    const [listener, setListener] = useState(null);

    function useMedia(query) {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            if (!showTOC) {
                let media = window.matchMedia(query);
                if (media.matches !== matches) {
                    setMatches(media.matches);
                }

                if (media.matches) {
                    setXsValue(12);
                } else {
                    setXsValue(12);
                }
                setListener(() => setMatches(media.matches));
                media.addListener(listener);
            }
        }, [query]);

        return matches;
    }

    useMedia('(orientation: landscape)');

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const setters = {
        setShowTOC,
        setLoading,
        setImgSrc,
        onDocumentUploaded,
        setMessage,
        setOpen,
        clearTimeout: () => clearTimeout(timeoutHandler.current),
    };

    const handleSuccess = (captureToken, b64CaptureFile) => {
        uploadFile(t, hashKey, dataURItoBlob(b64CaptureFile), b64CaptureFile, 'TOC', token, document, setters);
        clearTimeout(timeoutHandler.current);
        eventTrackingService.trackEvent('TOC_completed', 'TOC', null, 0);
    };

    const handleShowTOC = () => {
        setImgSrc(null);
        onDocumentUploaded(null);
        setShowTOC(true);
        eventTrackingService.trackEvent('TOC_opened', 'TOC', null, 0);
    };

    const handleShowTOCLiveness = () => {
        handleShowTOC();
        setShowLiveness(true);
    };

    const handleRecapture = () => {
        // TOC doesnt allow to get a new image on the same session
        getToken();
        handleShowTOC();
    };

    const handleFailure = (errorCode) => {
        eventTrackingService.trackTocError(errorCode, t('CAPTURE_ERRORS.TOC.' + errorCode), 'liveness');
        switch (errorCode) {
            case 'EXPIRED_TOKEN':
            case '402': // time limit exceeded
                getToken();
                break;
            case '405': // session expired
                getToken();
                break;
            case '403': // cancelled by user
            case '451': // cancelled by user
                setShowTOC(false);
                break;
            case '407': // camara no disponible
            case '409': // No hay permiso de cámara
                break;
            default:
                setShowTOC(false);
                setMessage(t('CAPTURE_ERRORS.TOC.' + errorCode));
                setOpen(true);
                break;
        }
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const CaptureSelfie = () => {
        return !imgSrc ? (
            showTOC && showLiveness ? (
                <div className="center tocContainer">
                    <div className="toc">
                        <TocAutocaptureSelfie session={token} onSuccess={handleSuccess} onFailure={handleFailure} />
                    </div>
                </div>
            ) : (
                <Grid item xs={12} lg={4} style={{ marginBottom: '20px !important' }}>
                    <Button
                        className="width-100-per"
                        onClick={handleShowTOCLiveness}
                        variant="contained"
                        color="primary"
                        startIcon={<img src={cameraIcon} alt="" />}>
                        <span className="fs-16 fw-400">REALIZAR PRUEBA DE VIDA</span>
                    </Button>
                </Grid>
            )
        ) : null;
    };

    return (
        <>
            <Loader loading={loading} />
            {document && token && (
                <>
                    <Grid item container xs={12} alignItems="center">
                        <p className="fs-20 fw-500 color-primary">{title} </p>
                        <div className="mt-1"></div>
                    </Grid>

                    {!imgSrc ? (
                        <>
                            <div className="center mb-3 mt-4">
                                <img src={sampleSrc} id="oldDni" alt=""></img>
                            </div>
                            <Grid item container xs={xsValue} className="mb-2">
                                {hintInfo}
                            </Grid>
                        </>
                    ) : (
                        <Grid item container xs={xsValue} />
                    )}

                    <Grid item container style={{ zIndex: 100, marginTop: '15px' }} xs={xsValue} justifyContent="center" alignItems="center">
                        {!imgSrc && <CaptureSelfie />}
                        {imgSrc && (
                            <div className="">
                                <img className={classes.captureStylePortrait} src={imgSrc} alt={document.name} />
                            </div>
                        )}
                    </Grid>

                    {imgSrc && (
                        <>
                            <Grid item xs={xsValue} style={{ marginTop: '50px', marginBottom: '20px' }}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    style={{ color: '#00A1DA', fontSize: '16px', fontWeight: '600' }}
                                    onClick={handleRecapture}
                                    className="center width-100-per">
                                    Capturar nuevamente
                                </Link>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={xsValue} className="">
                        {imgSrc && (
                            <div className="mt-2 width-100-per mb-2">
                                <Button onClick={onHandleNext} className="width-100-per" variant="contained" color="primary">
                                    <span className="fs-16 fw-400">Siguiente</span>
                                </Button>
                            </div>
                        )}
                    </Grid>

                    {open && (
                        <div className="mt-4">
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
