import React from 'react';
import back from '../../assets/back.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import useCurrentTask from '../bpm/useCurrentTask';

const useStyles = makeStyles(() => ({
    backButton: {
        backgroundColor: 'unset',
        border: 'unset',
        padding: '10px 20px',
        cursor: 'pointer',
    },
}));

const BackButton = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const { taskDefinitionKey } = props;
    const { hashKey } = props;
    const { keepSameTask } = props;
    const task = useCurrentTask(hashKey, taskDefinitionKey);

    const goBack = () => {
        if (taskDefinitionKey) {
            if (keepSameTask) {
                history.push({ pathname: `/${taskDefinitionKey}/${hashKey}`, search: history.location.search });
            } else {
                task.goToPreviousTask();
            }
        } else {
            history.push({ pathname: '/', search: history.location.search });
        }
    };

    if (location.pathname === '/') {
        return null;
    } else {
        return (
            <button onClick={goBack} className={classes.backButton}>
                {' '}
                <img src={back} alt="Volver" />
            </button>
        );
    }
};

export default BackButton;
