import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import divider from '../../assets/divider-line.svg';
import './../../styles/components/customerDataInput.scss';

export default function SteppersImage(props) {
    const renderElements = () => {
        var listElements = [];
        for (let index = 1; index <= props.elementsNumber; index++) {
            if (index !== 1) {
                listElements.push(<img src={divider} alt="" className="dividerNumber" />);
            }
            const typeStep = index < props.activeElement ? 'stepDone' : index === props.activeElement ? 'stepActive' : '';
            listElements.push(<Avatar className={'stepNumber ' + typeStep}>{index}</Avatar>);
        }
        return listElements;
    };

    return (
        <Grid item container xs={12} className="stepsContainer left">
            {renderElements()}
        </Grid>
    );
}
