import axiosInstance from './axios.config';

const RESTAPI_VERSION = '/v1';

const workflowService = {
    getUserInputFields: function (hashKey, stepName) {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/resolveuserinputs/${stepName}`);
    },
    start: (cuit, dni, name, firstName, lastName, sex, birthday, email, phone, pin, _marketingData, _promoterInfo) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/start`, {
            idCommerce: 4,
            idProduct: 999999,
            idSubProduct: 999999,
            birthDate: new Date(birthday),
            clientEmail: email,
            customerName: name,
            names: firstName,
            surNames: lastName,
            preCustomerData: {
                PtllaCUIT: cuit,
                PtllaSexo: sex,
                PtllaDNI: dni,
                PtllaDNIUsedValue: dni,
                PtllaTelCelular: phone,
                PtllaTelCelularVerificationCode: pin,
                PtllaFechaNacimiento: birthday,
                PtllaMail: email,
            },
            marketingData: _marketingData,
            promoterInfo: _promoterInfo
        });
    },
    getCurrentTask: function (hashKey) {
        return axiosInstance.get(`/v1/workflow/task/${hashKey}/current`);
    },
    completeTask: function (hashKey, task) {
        return axiosInstance.post(`/v1/workflow/task/${hashKey}/complete`, task);
    },
    triggerTaskEvent: function (hashKey, triggerConfig) {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/task/${hashKey}/trigger-event`, triggerConfig);
    },
    getUserInputComboData: function (hashKey, userInputName, stepName, conditioningValue) {
        const params = {
            hash: hashKey,
            stepName: stepName,
            conditioningValue: conditioningValue,
            userInputName: userInputName,
        };
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/userinput/combodata`, { params: params });
    },
    sendEmailValidation: (hashKey) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/emailVerificationCode`);
    },
    getUserInputData: function (hashKey, userInputName, stepName, conditioningValue) {
        const params = {
            hash: hashKey,
            stepName: stepName,
            conditioningValue: conditioningValue,
            userInputName: userInputName,
        };
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/userinput/data`, { params: params });
    },
    getFAQ: (task) => {
        const params = {
            page: task,
            section: task,
        };
        return axiosInstance.get(`${RESTAPI_VERSION}/faqs/`, { params: params });
    },
    canStartRequest: () => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/workingDay`);
    },
    getDecryptedQueryParams: function (encryptedQuery) {
        const params = {
            encryptedCustomerData: encryptedQuery,
        };
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/query-params`, { params: params });
    },
};

export default workflowService;