import React, { useEffect, useState } from 'react';
import successImage from './../assets/WelcomeCallOperator.svg';
import { Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import Loader from '../components/loader/Loader';
import { capitalizeText } from '../components/utils/capitalizeText';
import WelcomePage from './shared/WelcomePage';
import loanRequestService from '../services/loanRequest.service';

const MAX_WAIT_TIME = 60;

const WelcomeCallPage = (props) => {
    const { hashKey } = props.match.params;
    const [loanRequest, setLoanRequest] = useState(null);
    const [secondsToCheck, setSecondsToCheck] = useState(MAX_WAIT_TIME);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        const timerToCheck = setInterval(() => {
            setSecondsToCheck(secondsToCheck - 1);
        }, 1000);
        return () => clearInterval(timerToCheck);
    });

    async function getLoanRequest() {
        try {
            
            setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);

        } catch (error) {
            console.error(error.response.data);
        }
    }

    useEffect(() => {
        if (hashKey) {
            getLoanRequest();
        }
    }, [hashKey]);

    useEffect(() => {
        if (secondsToCheck <= 0) {
            getLoanRequest();
            setSecondsToCheck(MAX_WAIT_TIME);
        }
    }, [secondsToCheck]);

    if (!loanRequest) {
        return <Loader loading={true} />;
    }

    return (
        loanRequest && (
            <WelcomePage
                headerTitle={`${capitalizeText(loanRequest.customerName)}`}
                headerSubtitle="¡Tu préstamo está casi listo!"
                hideStepper={true}
                hideQuestions={true}
                className="">
                <Grid item container xs={12} sm={7} spacing={1}>
                    <Grid item>
                        <p className={`fw-700 mt-2 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                        Dentro de las próximas 48hs. estarás recibiendo un llamado para confirmar los datos de tu solicitud.
                        </p>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center" alignItems="flex-start"  className="mt-2 mb-3">
                        <Box className="mb-3"> <img className="fit-container width-90-per" src={successImage} alt=""></img> </Box>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                         <p className={`color-light-blue fw-400 fs-${smUp ? '24' : '18'}`}>Nos contactaremos en la franja horaria de 10 a 16 hs.</p>
                    </Grid>

                    <Grid item container xs={12} >
                        <Grid item xs={9}>
                            <p className={`mt-2 fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Monto solicitado</p>
                        </Grid>
                        <Grid item xs={3} className="text-align-right">
                            <p className={`mt-2 fw-700 color-primary fs-1${smUp ? '6' : '5'}`}>{numberToCurrencyText(loanRequest.requestedAmount)}</p>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={9}>
                            <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Cuotas</p>
                        </Grid>
                        <Grid item xs={3} className="text-align-right">
                            <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>{loanRequest.installmentsNumberSelected}</p>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={9}>
                            <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Monto de 1ra cuota</p>
                        </Grid>
                        <Grid item xs={3} className="text-align-right">
                            <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                                {numberToCurrencyText(loanRequest.firstInstallmentAmount)}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={9}>
                            <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Monto Total a Pagar</p>
                        </Grid>
                        <Grid item xs={3} className="text-align-right">
                            <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                                {numberToCurrencyText(loanRequest.totalRepaymentAmount)}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </WelcomePage>
        )
    );
};

export default WelcomeCallPage;
