import {convertDateFormat} from './dateUtils';

export const buildNpsUserInformation = (loanRequest) => {
    var user = {
                "user.entityMis"       : loanRequest.cobisCustomerId || null,
                "user.nombre"          : loanRequest.customerFirstName,
                "user.apellido"        : loanRequest.customerLastName,
                "user.email"           : loanRequest.customerEmail,
                "user.celular"         : "+54" + loanRequest.customerPhone,
                "user.transaccion.codProductoFinanciero": loanRequest.loanProduct,
                "user.fechaNacimiento" : loanRequest.birthDate.substring(0, 10),
                "user.genero"          : loanRequest.sex || null,
                "user.transaccion.numeroSolicitud" : loanRequest.sappCreditId,
                "user.transaccion.fechaSolicitud"  : convertDateFormat(loanRequest.date),
                "user.transaccion.status"          : loanRequest.status || null,
                "user.transaccion.productId"       : loanRequest.productId || null,
                "user.transaccion.subProductId"    : loanRequest.subProductId || null,
                "user.transaccion.utm_source"      : loanRequest.marketingData.utm_source || null,
                "user.transaccion.utm_medium"      : loanRequest.marketingData.utm_medium || null,
                "user.transaccion.utm_campaign"    : loanRequest.marketingData.utm_campaign || null,
                "user.transaccion.utm_content"     : loanRequest.marketingData.utm_content || null
        };

        return user;    

};