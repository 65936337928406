import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, Typography } from '@material-ui/core';
import documentService from '../services/document.service';
import useCurrentTask from '../components/bpm/useCurrentTask';
import '../styles/components/documentsUpload.scss';
import MacroStepPage from './shared/MacroStepPage';
import loanRequestService from '../services/loanRequest.service';
import eventTrackingService from '../services/eventtracking.service';
import selfie from './../assets/sampleSelfie.svg';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import { useTranslation } from 'react-i18next';
import Loader from './../components/loader/Loader';
import HelperPopup from '../components/login/helperPopup';
import DocumentsUploadHints from './shared/DocumentsUploadHints';
import { DocumentUploaderSelfie } from '../components/documentsUpload/DocumentUploaderSelfie';
import { ShowValidationError } from '../components/documentsUpload/DocumentUploaderCommon';
import SteppersImage from './shared/SteppersImage';

export const DocumentsUploadSelfie = (props) => {
    const { hashKey } = props.match.params;
    const [loanRequest, setLoanRequest] = useState(undefined);
    const [token, setToken] = useState(null);
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [activateNext, setActivateNext] = useState(true);
    const { t } = useTranslation();
    const analyticsStep = 8;
    const [documentsUploaded, setDocumentsUploaded] = useState(false);
    const [documents, setDocuments] = useState(undefined);
    const [loading, setLoading] = useState(null);

    const [imgSrc, setImgSrc] = useState(null);

    const task = useCurrentTask(hashKey, props.taskDefinitionKey);

    async function getLoanRequest() {
        try {
            setLoading(true);
            setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getLoanRequest();
    }, [hashKey]);

    useEffect(() => {
        async function getDocuments() {
            try {
                setLoading(true);
                setDocuments((await documentService.getDocuments(hashKey, 'documentsUpload')).data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        if (loanRequest) {
            setDocumentsUploaded(loanRequest.documentsCompleted);
            getDocuments();
        }
    }, [loanRequest]);

    const onDocumentUploaded = (imgSrcUploaded) => {
        eventTrackingService.trackEvent('selfie', loanRequest.documentUploadAccessMethod === 'qr' ? 'con qr' : 'sin qr', null, analyticsStep);
        setImgSrc(imgSrcUploaded);
        setActivateNext(imgSrcUploaded ? true : false);
    };

    const handleNext = async () => {
        try {
            setLoading(true);

            eventTrackingService.trackEvent(
                'selfie completed',
                loanRequest.documentUploadAccessMethod === 'qr' ? 'con qr' : 'sin qr',
                null,
                analyticsStep
            );

            await task.completeTask();
        } catch (error) {
            setHasValidationErrors(true);
            if (error.response) {
                if (error.response.data) {
                    setErrorCode(error.response.data.errorCode);
                } else {
                    console.error('Error: ' + error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRestartFaceValidation = () => {
        setToken(null);
        setHasValidationErrors(false);
    };

    const handleGetToken = (newToken) => {
        setToken(newToken);
    };

    const hintSelfie = useMemo(() => DocumentsUploadHints(true), []);

    const helperInfoPopup = (
        <HelperPopup helperText="¿Por qué pedimos tus fotos?" dialogTitle="¿Por qué pedimos tus fotos?" dialogAction="Entendido">
            <Typography gutterBottom>
                Por tu seguridad, necesitamos que saques fotos de tu DNI y rostro para verificar que sos vos quien está solicitando el préstamo
            </Typography>
        </HelperPopup>
    );

    return (
        <>
            <Loader loading={loading} />
            <MacroStepPage
                headerLead="Estás muy cerca de tu préstamo"
                headerTitle="Realizá la prueba de vida"
                hashKey={hashKey}
                task={task}
                taskDefinitionKey={props.taskDefinitionKey}
                hideStepper={true}
                stepNumber={1}
                totalSteps={4}
                className="mt-1">
                {isMobile ? (
                    loanRequest && documents && !hasValidationErrors ? (
                        <>
                            <Loader loading={loading} />
                            <SteppersImage elementsNumber={3} activeElement={3} />
                            <Grid item container style={{ zIndex: 100 }} xs={12} direction="row" justifyContent="center">
                                <DocumentUploaderSelfie
                                    title="Prueba de vida"
                                    sampleSrc={selfie}
                                    hintInfo={hintSelfie}
                                    hashKey={loanRequest.hashKey}
                                    document={documents[2]}
                                    previousToken={token}
                                    onGetToken={handleGetToken}
                                    onDocumentUploaded={onDocumentUploaded}
                                    onHandleNext={handleNext}
                                />
                            </Grid>
                        </>
                    ) : (
                        hasValidationErrors && ShowValidationError(handleRestartFaceValidation, true)
                    )
                ) : (
                    <ContinueMobile hashKey={hashKey} />
                )}
            </MacroStepPage>
        </>
    );
};
