import React from 'react';
import sadPc from '../assets/sadpc.svg';
import Button from '@material-ui/core/Button';
import '../styles/components/error.scss';
import { Grid } from '@material-ui/core';
import MacroPage from './shared/MacroPage';

export const ErrorPage = (props) => {
    const { history } = props;
    const width = window.innerWidth;
    const breakpoint = 600;

    const handleBack = (event) => {
        event.preventDefault();
        history.goBack();
    };

    const returnDesktopOrMobile =
        width > breakpoint ? (
            <MacroPage>
                <div className="imgcontentError ml-4">
                    <div className="leftSideError">
                        <div className="titleError">
                            <p className="fw-400 fs-30 color-primary">No podemos avanzar, veamos porqué...</p>
                        </div>
                        <div className="subTitleError">
                            <p className="fw-300 fs-16 color-dark-gray">En este momento estamos teniendo problemas técnicos y no podemos seguir.</p>
                        </div>
                        <div className="subTitle2Error">
                            <p className="fw-300 fs-16 color-dark-gray">Te recomendamos que intentes más tarde.</p>
                        </div>
                        <div className="buttonError mb-2">
                            <Button className="flex center" variant="contained" color="primary" onClick={handleBack}>
                                Volver
                            </Button>
                        </div>
                    </div>
                    <div className="righSideError">
                        <img src={sadPc} alt=""></img>
                    </div>
                </div>
            </MacroPage>
        ) : (
            <MacroPage>
                <div className="error-container">
                    <p className="fw-400 fs-30 color-primary">No podemos avanzar, veamos porqué...</p>
                    <img src={sadPc} alt=""></img>
                    <p className="fw-300 fs-16 color-dark-gray">En este momento estamos teniendo problemas técnicos y no podemos seguir.</p>
                    <div className="mt-2"></div>
                    <p className="fw-300 fs-16 color-dark-gray">Te recomendamos que intentes más tarde.</p>
                    <div className="mt-2"></div>
                </div>
                <Grid item xs={12}>
                    <div className="startButton center">
                        <Button className="flex center mb-2 width-80-per" variant="contained" color="primary" onClick={handleBack}>
                            Volver
                        </Button>
                    </div>
                </Grid>
            </MacroPage>
        );

    return <>{returnDesktopOrMobile}</>;
};
