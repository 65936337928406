import React, { useState, useEffect } from 'react';
import { UNDEFINED_VALUE } from './userInputConstants';
import { CustomTextField } from '../customInputs/CustomTextField';

const EmailUserInput = (props) => {
    const { userInput, onChange } = props;
    const [editedValue, setEditedValue] = useState(userInput.userInputOptions.defaultValue || UNDEFINED_VALUE);
    const [value, setValue] = useState(userInput.userInputOptions.defaultValue || UNDEFINED_VALUE);
    const userId = `ID_${userInput.userInputOptions.label}`;

    useEffect(() => {
        if (value) {
            onChange(userInput, value);
        }
    }, [value]);

    const handleValueChange = (event) => {
        setEditedValue(event.target.value);
    };

    const handleEndChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <CustomTextField
            type="email"
            id={userId}
            disabled={userInput.userInputOptions.disabled}
            fullWidth={true}
            required={userInput.userInputOptions.required}
            value={editedValue}
            name={userInput.name}
            onChange={handleValueChange}
            onBlur={handleEndChange}
            label={userInput.userInputOptions.label}
            errorHelperText="Requerido. Cargá un email válido"
            helperText={userInput.userInputOptions.hint}
        />
    );
};

export default EmailUserInput;
