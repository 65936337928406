import React, { useState, useMemo } from 'react';
import identityValidation from './../assets/identity-validation.svg';
import identityValidationError from './../assets/identity-validation-error.svg';
import MacroPage from './shared/MacroPage';
import { Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import '../styles/components/error.scss';
import '../styles/components/validationIdentityPage.scss';
import '../styles/components/toc.scss';
import MainButton from '../components/MainButton';
import TocAutocaptureSelfie from '../components/documentsUpload/TocAutocaptureSelfie';
import Toaster from '../components/alerts/Toaster';
import documentService from './../services/document.service';
import workflowService from '../services/workflow.service';
import loanRequestService from '../services/loanRequest.service';

import { isMobile } from 'react-device-detect';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import eventTrackingService from '../services/eventtracking.service';
import { calculateAge } from '../components/utils/dateUtils';
import { ANALYTICS_EVENT, ERROR, LOAN_REQUEST_STATUS, ROUTE } from './../constants/global';

export const IdentityValidation = (props) => {
    const { history } = props;
    const { hashKey } = props.match.params;
    const { t } = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [token, setToken] = useState(null);
    const [captureImg, setCaptureImg] = useState(false);
    const [message, setMessage] = useState('Se produjo un error');

    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const handleGetImage = (event) => {
        getToken();
    };

    const getToken = async () => {
        try {
            const newToken = (await documentService.getUploadToken(hashKey)).data;
            setToken(newToken);
            setCaptureImg(true);
        } catch (error) {
            setCaptureImg(false);
            console.error(error.response);
            setMessage(error.response.data);
            setOpen(true);
        }
    };

    const getCurrentTask = async (hashKey) => {
        try {
            const currentTask = (await workflowService.getCurrentTask(hashKey)).data;
            history.push({ pathname: `/${currentTask.taskDefinitionKey}/${hashKey}`, search: history.location.search });
        } catch (error) {
            if (error.response) {
                const { errorCode } = error.response.data;
                if (errorCode === ERROR.NO_ACTIVE_TASKS) {
                    const loanRequest = (await loanRequestService.getLoanRequest(hashKey)).data;
                    switch (loanRequest.status) {
                        case LOAN_REQUEST_STATUS.REJECTED:
                            eventTrackingService.trackEventWithData(
                                ANALYTICS_EVENT.NO_OFFER,
                                {
                                    userID: loanRequest.customerId,
                                    edad: calculateAge(loanRequest.birthDate),
                                    género: loanRequest.sex,
                                },
                                null
                            );
                            history.push({
                                pathname: `${ROUTE.REJECTED}/${loanRequest.response.rejectedReasonCode}`,
                                search: history.location.search,
                            });
                            break;
                        case LOAN_REQUEST_STATUS.FINALIZED:
                        case LOAN_REQUEST_STATUS.ACTIVATION_PROCESS:
                            history.push({ pathname: `${ROUTE.SUCCESS}/${hashKey}`, search: history.location.search });
                            break;
                        default:
                            break;
                    }
                } else {
                    setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) || 'Error durante la ejecución');
                    setOpen(true);
                    throw error;
                }
            }
        }
    };

    const validateIdentity = async (file) => {
        try {
            let result = await documentService.identityValidation(hashKey, document.name, token, file);
            let header = result.headers;
            localStorage.setItem('token', header.authorization);
            eventTrackingService.trackEvent('retoma_onboarding_exito', '', null, 5);
            setError(false);
            getCurrentTask(hashKey);
        } catch (error) {
            setError(true);
            setCaptureImg(false);

            if (error.response.data.errorCode) {
                setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.data.errorCode}.DESCRIPTION`));
                setOpen(true);
            } else {
                setMessage(t(`RENAPER_VALIDATION_ERROR.${error.response.status}.DESCRIPTION`));
                setOpen(true);
            }
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const identityValidationImage = <img src={identityValidation} alt=""></img>;

    const dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const handleSuccess = (captureToken, b64CaptureFile) => {
        validateIdentity(dataURItoBlob(b64CaptureFile));
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleFailure = (errorCode) => {
        eventTrackingService.trackTocError(errorCode, t('CAPTURE_ERRORS.TOC.' + errorCode), 'liveness');
        switch (errorCode) {
            case 'EXPIRED_SESSION':
                // time limit exceeded
                console.error('time limit exceeded');
                getToken();
                break;
            case '402':
                // time limit exceeded
                console.error('time limit exceeded');
                getToken();
                break;
            case '403':
                console.error('cancelled by user');
                setCaptureImg(true);
                break;
            case '405':
                // session expired
                console.error('session expired');
                getToken();
                setCaptureImg(true);
                break;
            case '407':
                console.error(t('CAPTURE_ERRORS.TOC.' + errorCode));
                setCaptureImg(false);
                break;
            default:
                setCaptureImg(false);
                console.error(t('CAPTURE_ERRORS.TOC.' + errorCode));
                setMessage(t('CAPTURE_ERRORS.TOC.' + errorCode));
                setOpen(true);
                break;
        }
    };

    const [error, setError] = useState(false);

    const data = useMemo(() => {
        if (error) {
            return {
                title: <>{t('IDENTITY_VALIDATION.TITLE.ERROR')}</>,
                img: <img src={identityValidationError} alt=""></img>,
                description: t('IDENTITY_VALIDATION.DESCRIPTION.ERROR'),
                button: t('IDENTITY_VALIDATION.BUTTON.ERROR'),
            };
        } else {
            return {
                title: (
                    <>
                        ¡Hola de nuevo!
                        <br />
                        {t('IDENTITY_VALIDATION.TITLE.OK')}
                    </>
                ),
                img: <img src={identityValidation} alt=""></img>,
                description: t('IDENTITY_VALIDATION.DESCRIPTION.OK'),
                button: t('IDENTITY_VALIDATION.BUTTON.OK'),
            };
        }
    }, [error, t]);

    return !isMobile ? (
        <MacroPage>
            <ContinueMobile hashKey={hashKey} />
        </MacroPage>
    ) : (
        <>
            <MacroPage>
                <Grid container direction="column" className="">
                    <Grid item xs={12}>
                        <Box className="pushDiv"></Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container direction="row" alignItems="center" justifyContent="center" className="validationContainer">
                            <Grid item container xs={10} direction="row" alignItems="center" className="">
                                <Grid item container xs={12} md={8} direction="column" className="">
                                    <Grid item xs={12}>
                                        <p className="fs-30-res fw-400 ls-33 color-primary">{data.title}</p>
                                    </Grid>

                                    {smDown && (
                                        <Grid item container alignItems="center" xs={10}>
                                            <Box className="mt-3"></Box>
                                            {data.img}
                                        </Grid>
                                    )}

                                    <Grid item className="mt-4 mb-130-res" xs={12}>
                                        <Box className="mt-3"> </Box>
                                        <p className="fs-16 color-dark-gray fw-400">{data.description}</p>
                                    </Grid>

                                    {!smDown && !captureImg && (
                                        <Grid item xs={12} sm={6}>
                                            <MainButton onClick={handleGetImage}>{data.button}</MainButton>
                                        </Grid>
                                    )}
                                </Grid>

                                {!smDown && (
                                    <Grid item container direction="column" xs={12} md={4} className="">
                                        {identityValidationImage}
                                    </Grid>
                                )}
                                {captureImg && token && (
                                    <div className="center tocContainer">
                                        <div className="toc">
                                            <TocAutocaptureSelfie session={token} onSuccess={handleSuccess} onFailure={handleFailure} />
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {smDown && !captureImg && (
                    <Grid item xs={12}>
                        <div className="startButton center fixed-bottom-padding">
                            <Button className="flex center width-80-per" variant="contained" color="primary" onClick={handleGetImage}>
                                {data.button}
                            </Button>
                        </div>
                    </Grid>
                )}

                {open && (
                    <div className="mt-4">
                        <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                    </div>
                )}
            </MacroPage>
        </>
    );
};
