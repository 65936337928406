import axiosInstance from './axios.config';

const RESTAPI_VERSION = '/v1';

const offerService = {
    setMaxAmountLine: (hashKey) => {
        return axiosInstance.put(`${RESTAPI_VERSION}/workflow/loanRequest/maxamountline/${hashKey}`, {});
    },
    getAvailableAmount: (hashKey) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/availableAmount`, {});
    },
    getInstallments: (hashKey, requestedAmount) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/installments-simulation`, {
            requestedAmount: requestedAmount,
        });
    },
    getSimulationInstallments: (requestedAmount, isCustomer) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/installments-simulation`, {
            requestedAmount: requestedAmount,
            isCustomer: isCustomer,
        });
    },
    setInstallment: (hashKey, installmentDto) => {
        return axiosInstance.put(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/installments`, installmentDto);
    },
};

export default offerService;
