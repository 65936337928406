import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

export const CustomTextField = ({ onChange, onInvalid, helperText, errorHelperText, ...props }) => {
    const [error, setError] = useState(false);

    const handleInvalid = (event) => {
        event.preventDefault();
        if (onInvalid) {
            onInvalid(event);
        }
        setError(true);
    };

    const handleChange = (...args) => {
        if (onChange) {
            onChange(...args);
        }
        setError(false);
    };

    return (
        <TextField
            onInvalid={handleInvalid}
            onChange={handleChange}
            error={error}
            helperText={error ? errorHelperText : helperText}
            {...props} />
    );
};
