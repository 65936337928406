import React from 'react';
import sessionExpiredImg from './../assets/session_expired.svg';
import { Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import '../styles/components/error.scss';
import '../styles/components/sessionExpired.scss';
import MacroPage from './shared/MacroPage';
import MainButton from '../components/MainButton';

export const SessionExpired = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const handleReconnect = (event) => {
        event.preventDefault();
        history.push({ pathname: `/customerIdentification`, search: history.location.search });
    };

    const sessionExpiredImage = <img src={sessionExpiredImg} alt=""></img>;
    return (
        <>
            <MacroPage>
                <Grid container direction="column" className="">
                    <Grid item xs={12}>
                        <Box className="pushDiv"></Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container direction="row" alignItems="center" justifyContent="center" className="sessionContainer">
                            <Grid item container xs={10} direction="row" alignItems="center" className="">
                                <Grid item container xs={12} md={8} direction="column" className="">
                                    <Grid item xs={12}>
                                        <p className="fs-30 fw-400 ls-33 color-primary">{t('SESSION_EXPIRED.TITLE')}</p>
                                    </Grid>

                                    {smDown && (
                                        <Grid item container alignItems="center" xs={10}>
                                            <Box className="mt-3"></Box>
                                            {sessionExpiredImage}
                                        </Grid>
                                    )}

                                    <Grid item className="mt-4" xs={12}>
                                        <Box className="mt-3"> </Box>
                                        <p className="fs-16 color-dark-gray fw-400">{t('SESSION_EXPIRED.DESCRIPTION')}</p>
                                    </Grid>

                                    {!smDown && (
                                        <Grid item xs={12} sm={6}>
                                            <MainButton onClick={handleReconnect}>{t('SESSION_EXPIRED.BUTTON')}</MainButton>
                                        </Grid>
                                    )}
                                </Grid>

                                {!smDown && (
                                    <Grid item container direction="column" xs={12} md={4} className="">
                                        {sessionExpiredImage}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {smDown && (
                    <Grid item xs={12}>
                        <div className="startButton center fixed-bottom-padding">
                            <Button className="flex center width-80-per" variant="contained" color="primary" onClick={handleReconnect}>
                                {t('SESSION_EXPIRED.BUTTON')}
                            </Button>
                        </div>
                    </Grid>
                )}
            </MacroPage>
        </>
    );
};
