import React, { useEffect, useState } from 'react';
import { Button, Typography, makeStyles, Modal, Grid, Box } from '@material-ui/core';
import whatsappImg from '../../assets/whatsapp.svg';
import {
    getEnv,
    WHATSAPP_AVALAIBLE,
    WHATSAPP_DAYS_AVALAIBLE,
    WHATSAPP_START_TIME_AVALAIBLE,
    WHATSAPP_END_TIME_AVALAIBLE,
    WHATSAPP_LOCAL_PHONE_NUMBER,
    WHATSAPP_TIME_BEFORE_SHOW_SEGS,
    WHATSAPP_TEXT_MESSAGE,
} from '../../env';

const global = {
    modalText: 'Vamos a redirigirte a Whatsapp para ayudarte con tu solicitud.',
    modalCancelButton: 'No gracias',
    modalContinueButton: 'Ir a Whatsapp',
    defaultTextToSend: '¡Hola! Necesito ayuda con mi solicitud.',
};

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        boxShadow: theme.shadows[5],
        left: '50%',
        marginRight: '-50%',
        maxWidth: 260,
        outline: 0,
        padding: theme.spacing(3, 2, 1),
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    modalButton: {
        height: '40px',
        width: '100%',
    },
    whatsappDiv: {
        bottom: 0,
        cursor: 'pointer',
        float: 'right',
        marginBottom: '60px',
        marginRight: '10px',
        position: 'fixed',
        right: 0,
        zIndex: 1000,
    },
    whatsappIcon: {
        width: '50px',
        filter: 'drop-shadow(2.5px 2.5px 5px rgba(0, 0, 0, .3))',
        animation: 'fadein 0.5s',
    },
}));

export default function WhatsappComponent() {
    const [open, setOpen] = useState(false);
    const [isVisibleWhatsapp, setIsVisibleWhatsapp] = useState(false);
    const classes = useStyles();
    const text = getEnv(WHATSAPP_TEXT_MESSAGE) || global.defaultTextToSend;

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleContinue = () => {
        const phoneNumber = getEnv(WHATSAPP_LOCAL_PHONE_NUMBER);
        window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(text)}`);
    };

    const openModal = () => {
        setOpen(true);
    };

    const generateWhatsappIcon = (text) => {
        return (
            <>
                <Modal open={open} onClose={handleCloseModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {
                        <Grid container className={classes.paper} spacing={2}>
                            <Grid item xs={12}>
                                <Typography> {global.modalText}</Typography>
                                <br />
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleCloseModal} className={classes.modalButton} variant="outlined" color="primary">
                                    {global.modalCancelButton}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleContinue} className={classes.modalButton} variant="contained" color="primary">
                                    {global.modalContinueButton}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Modal>
                <div className={classes.whatsappDiv} onClick={openModal}>
                    <img src={whatsappImg} className={classes.whatsappIcon} alt="Whatsapp" />
                </div>
            </>
        );
    };

    const isAvailabilityWhatsapp = () => {
        const date = new Date();
        const available = getEnv(WHATSAPP_AVALAIBLE);
        const days = getEnv(WHATSAPP_DAYS_AVALAIBLE)?.split(',');
        const startTime = getEnv(WHATSAPP_START_TIME_AVALAIBLE)?.split(':');
        const endTime = getEnv(WHATSAPP_END_TIME_AVALAIBLE)?.split(':');

        if (!available || available === 'false') {
            console.log('Is not available');
            return false;
        }
        if (!days || !days.includes(date.getDay().toString())) {
            console.log('Is not available day');
            return false;
        }
        if (
            !startTime ||
            Number(startTime[0]) > date.getHours() ||
            (Number(startTime[0]) === date.getHours() && Number(startTime[1]) > date.getMinutes())
        ) {
            console.log('Is not available startTime');
            return false;
        }
        if (!endTime || Number(endTime[0]) < date.getHours() || (Number(endTime[0]) === date.getHours() && Number(endTime[1]) < date.getMinutes())) {
            console.log('Is not available endTime');
            return false;
        }

        return true;
    };

    const loadWebchat = async () => {
        if (isAvailabilityWhatsapp()) {
            const time = getEnv(WHATSAPP_TIME_BEFORE_SHOW_SEGS);
            setTimeout(() => setIsVisibleWhatsapp(true), time ? Number(time) * 1000 : 3000);
        }
    };

    useEffect(() => {
        loadWebchat();
    }, []);

    return isVisibleWhatsapp && generateWhatsappIcon('', '');
}
