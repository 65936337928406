export const ANALYTICS_EVENT = {
    NO_OFFER: 'prestamos_oferta_no_disponible',
};

export const ERROR = {
    DEVICE_VALIDATION: 'DEVICE_VALIDATION',
    LOAN_REQUEST_EXPIRED: 'LOAN_REQUEST_EXPIRED',
    NO_ACTIVE_TASKS: 'NO_ACTIVE_TASKS',
};

export const LOAN_REQUEST_STATUS = {
    ACTIVATION_PROCESS: 'LIQ',
    FINALIZED: 'ACT',
    REJECTED: 'REC',
};

export const LOCAL_STORAGE = {
    ONBOARDING_COMPLETED_DATE: 'onboardingCompletedDate',
    ONBOARDING_COMPLETED_TAX_ID: 'onboardingCompletedTaxId',
};

export const ROUTE = {
    ERROR: '/error',
    REJECTED: '/rejected',
    SUCCESS: '/loanActivation',
};
