import React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import '../../styles/components/shared/infoBox.scss';

const InfoBox = (props) => {
    const theme = useTheme();
    const downSm = useMediaQuery(theme.breakpoints.down('sm'));

    return <div className={`info-box ${downSm ? 'fs-15' : 'fs-16'} ${props.className}`}>{props.children}</div>;
};

export default InfoBox;
