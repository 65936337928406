import React, { useEffect, useState } from 'react';
import successImage from './../assets/successLoan.svg';
import { Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
import { numberToCurrencyText } from './../components/utils/numberUtils';
import { useHistory, Link } from 'react-router-dom';
import documentService from '../services/document.service';
import Loader from '../components/loader/Loader';
import LoanRequestResultPage from './shared/LoanRequestResultPage';
import { capitalizeText } from '../components/utils/capitalizeText';
import loanRequestService from '../services/loanRequest.service';
import { buildNpsUserInformation } from '../components/utils/NpsUtils';
import NpsScriptRunner from '../components/nps/npsScriptRunner';

const MAX_WAIT_TIME = 60;

const CustomerLoanRequestSuccessPage = (props) => {
    const { hashKey } = props.match.params;
    const [loanRequest, setLoanRequest] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [secondsToCheck, setSecondsToCheck] = useState(MAX_WAIT_TIME);
    const theme = useTheme();
    const history = useHistory();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleBack = (event) => {
        event.preventDefault();
        history.push({ pathname: `/`, search: history.location.search });
    };

    useEffect(() => {
        const timerToCheck = setInterval(() => {
            setSecondsToCheck(secondsToCheck - 1);
        }, 1000);
        return () => clearInterval(timerToCheck);
    });

    async function getLoanRequest() {
        try {
            setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);
        } catch (error) {
            console.error(error.response.data);
        }
    }

    useEffect(() => {
        if (loanRequest) {
            setUserInfo(buildNpsUserInformation(loanRequest));
        }
    }, loanRequest);

    useEffect(() => {
        if (hashKey) {
            getLoanRequest();
        }
    }, [hashKey]);

    useEffect(() => {
        if (secondsToCheck <= 0) {
            getLoanRequest();
            setSecondsToCheck(MAX_WAIT_TIME);
        }
    }, [secondsToCheck]);

    const handleDownloadInstallmentPlan = (event) => {
        event.preventDefault();
        documentService.openInstallmentPlanDocumentInNewTab(loanRequest.hashKey);
    };

    if (!loanRequest) {
        return <Loader loading={true} />;
    }

    const getTitle = () => {
        return loanRequest.status === 'ACT' ? 'Se acreditó en tu cuenta de Banco Macro' : 'En las próximas horas se acreditará en tu cuenta.';
    };

    const getDownloadInstallmentPlanLink = () => {
        return loanRequest.status === 'ACT' ? (
            <>
                <div className="center color-primary mb-2">
                    <Link style={{ fontWeight: '500', color: '#092f57' }} href="#" onClick={handleDownloadInstallmentPlan}>
                        DESCARGAR EL DETALLE DE CUOTAS
                    </Link>
                </div>
            </>
        ) : (
            <></>
        );
    };

    const getNewAccountDisclaimer = () => {
        return loanRequest.newAccount ? (
            <>
                {' '}
                Recordá que te {loanRequest.status === 'ACT' ? 'abrimos' : 'abriremos'} una caja de ahorro{' '}
                <span className="fw-700 color-dark-gray">100% gratuita</span> en dónde se va depositar tu préstamo y las cuotas se debitarán de la
                misma.{' '}
            </>
        ) : (
            <></>
        );
    };

    return (
        loanRequest && (
            <>
                {userInfo && <NpsScriptRunner user={userInfo} />}
                <LoanRequestResultPage
                    // headerLead={'¡Tenés el dinero en tu cuenta!'}
                    headerTitle={`¡Felicitaciones ${capitalizeText(loanRequest.customerName)}!`}
                    headerSubtitle="El préstamo ya es tuyo"
                    title={getTitle()}
                    hideStepper={true}
                    hideQuestions={true}
                    resultImage={<img className="fit-container width-90-per" src={successImage} alt=""></img>}
                    className="">
                    <Grid item container xs={12} sm={7} spacing={1}>
                        <Grid item container xs={12}>
                            <Grid item xs={9}>
                                <p className={`mt-2 fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Monto Acreditado</p>
                            </Grid>
                            <Grid item xs={3} className="text-align-right">
                                <p className={`mt-2 fw-700 color-primary fs-1${smUp ? '6' : '5'}`}>
                                    {numberToCurrencyText(loanRequest.requestedAmount)}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={9}>
                                <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Cuotas</p>
                            </Grid>
                            <Grid item xs={3} className="text-align-right">
                                <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>{loanRequest.installmentsNumberSelected}</p>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={9}>
                                <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Monto de 1ra cuota</p>
                            </Grid>
                            <Grid item xs={3} className="text-align-right">
                                <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                                    {numberToCurrencyText(loanRequest.firstInstallmentAmount)}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={9}>
                                <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Monto Total a Pagar</p>
                            </Grid>
                            <Grid item xs={3} className="text-align-right">
                                <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                                    {numberToCurrencyText(loanRequest.totalRepaymentAmount)}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item>
                            <Box className="mt-2">
                                <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>{getNewAccountDisclaimer()}</p>
                            </Box>
                        </Grid>
                        <Grid item>
                            <p className={`fw-700 mt-2 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                                Te enviaremos un e-mail con detalle de tu préstamo liquidado.
                            </p>
                        </Grid>
                        <Grid item container style={{ marginTop: '30px', marginBottom: '30px' }}>
                            <Grid item xs={12} md={8}>
                                {getDownloadInstallmentPlanLink()}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <p className={`fw-700 mb-3 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                                Si la solicitud es generada días hábiles de 7:30 a 21:00 hs., contarás con la acreditación del préstamo en el momento.
                                De lo contrario, la acreditación se realizará a partir de las 7:30 hs. del próximo día hábil.
                            </p>
                        </Grid>
                    </Grid>
                </LoanRequestResultPage>
            </>
        )
    );
};

export default CustomerLoanRequestSuccessPage;
