import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import MaskedInput from 'react-text-mask';
import '../../../styles/components/tabs.scss';
import { CustomDatePicker } from '../../customInputs/CustomDatePicker';
import { ApiContext } from '../useLogin';

const CuitMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
            guide={false}
            placeholderChar={'\u2000'}
        />
    );
};

CuitMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const CUIT_REGEX = /[1-9]\d-\d{8}-[1-9]/;

export const CheckCuit = (props) => {
    const { request, setRequest, cuits } = useContext(ApiContext);
    const { onComplete } = props;
    const { manualFirstName, setManualFirstName } = props.manualFirstName;
    const { manualLastName, setManualLastName } = props.manualLastName;
    const [manualCuitError, setManualCuitError] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [manualCuit, setManualCuit] = useState('');
    const [birthDate, setBirthDate] = useState(null);
    const [cuit, setCuit] = useState(false);

    const obfuscate = (fullname) => {
        const names = fullname.trim().split(' ');
        var result = names[0].substring(0, 3) + names[0].substring(3).replace(/[a-zñA-ZÑ]/g, '*') + ' ';

        names.forEach((item, index) => {
            if (index > 0 && index < names.length - 1) {
                result += item.replace(/[a-zA-Z]/g, '*') + ' ';
            }
        });

        result +=
            names[names.length - 1].substring(0, names[names.length - 1].length - 3).replace(/[a-zA-Z]/g, '*') +
            names[names.length - 1].substring(names[names.length - 1].length - 3) +
            ' ';

        return result;
    };

    const handleManualCuitChange = (e) => {
        const cuit = e.target.value.trim();
        setManualCuitError(false);
        setManualCuit(cuit);
        setRequest({
            ...request,
            cuit: cuit.replaceAll(/-/g, ''),
        });
    };

    const handleFirstNameChange = (e) => {
        setManualFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setManualLastName(e.target.value);
    };

    const handleDateChange = (e) => {
        if (e == 'Invalid Date') {
            setBirthDate(null);
            setRequest({
                ...request,
                birthday: null,
            });
        } else {
            setBirthDate(e);
            setRequest({
                ...request,
                birthday: e,
            });
        }
    };

    const handleCustomerTaxIdChange = (e) => {
        setCuit(e.target.id);
        setRequest({
            ...request,
            cuit: e.target.id,
            sex: '',
            name: e.target.value,
            birthday: null,
            email: null,
        });
    };

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleComplete = (e) => {
        if (cuit || manualCuit.match(CUIT_REGEX)) {
            onComplete();
            return;
        }
        setManualCuitError(true);
    };

    return (
        <Box className="mt-2-5">
            {cuits.length > 1 && (
                <div className="cuitDescription mt-3 mb-3">
                    <span className="fs-14 color-darker-gray">
                        <div className="select-cuit-desc-title">
                            <span>¿Cuál de estas personas eres? </span>
                            <Button variant="text" onClick={handleClickOpenPopup} color="dark-gray">
                                <HelpIcon style={{ zIndex: 999, height: '30px', fontSize: 24, color: 'dark-gray' }} />
                            </Button>
                        </div>
                        <Dialog onClose={handleClosePopup} aria-labelledby="help-dialog-title" open={openPopup}>
                            <DialogTitle id="help-dialog-title" onClose={handleClosePopup}>
                                Ayuda
                            </DialogTitle>
                            <DialogContent dividers>
                                <Typography gutterBottom>
                                    Encontramos más de una coincidencia para este DNI. Por seguridad, los nombres aparecen ofuscados.
                                </Typography>
                                <Typography>Ejemplo:</Typography>
                                <Typography style={{ fontWeight: 'bold' }}>Perez García Juan Pablo se verá como PER** ***** **** **BLO</Typography>
                                <Typography gutterBottom>Por favor, seleccioná quién sos para continuar.</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={handleClosePopup} color="primary">
                                    Entendido
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </span>
                    {cuits.map((cuit) => (
                        <>
                            <div className="separator mt-05 mb-05"></div>
                            <div key={cuit.id} value={cuit.id} className="flex">
                                <input
                                    onChange={handleCustomerTaxIdChange}
                                    type="radio"
                                    id={cuit.id}
                                    name="cuit"
                                    value={(cuit.id, cuit.descripcion)}
                                />

                                <div className="ml-03-per">
                                    <div className="fw-400 fs-15 color-black">{obfuscate(cuit.descripcion)}</div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            )}{' '}
            {cuits.length < 1 && (
                <div className="mb-3">
                    <Grid container direction="row" justifyContent="flex-start" className="">
                        <Grid item xs={12} sm={8} lg={8} className="mt-2">
                            <TextField
                                id="standard-basic"
                                label="Ingresá tu CUIT sin puntos ni espacios"
                                className="width-100-per"
                                style={{ textAlign: 'left' }}
                                name="manualCuit"
                                onChange={handleManualCuitChange}
                                value={manualCuit}
                                error={manualCuitError}
                                helperText={manualCuitError && 'Debés ingresar un CUIT válido'}
                                InputProps={{ inputComponent: CuitMaskCustom }}
                                required="true"
                                type="tel"
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8} className="mt-4">
                            <div className="mt-2"></div>
                            <TextField
                                id="standard-basic"
                                label="Ingresá tu nombre"
                                className="width-100-per"
                                style={{ textAlign: 'left' }}
                                name="manualFirstName"
                                onChange={handleFirstNameChange}
                                value={manualFirstName}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8} className="mt-4">
                            <div className="mt-2"></div>
                            <TextField
                                id="standard-basic"
                                label="Ingresá tu apellido"
                                className="width-100-per"
                                style={{ textAlign: 'left' }}
                                name="manualLastName"
                                onChange={handleLastNameChange}
                                value={manualLastName}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8} className="mt-4">
                            <div className="mt-2"></div>
                            <CustomDatePicker
                                style={{ marginTop: '0%', width: '100%' }}
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="datePicker"
                                label="Fecha de nacimiento"
                                value={birthDate}
                                errorHelperText="Completá la fecha con el formato dd/mm/aaaa"
                                onChange={(date) => handleDateChange(date)}
                                required={true}
                                KeyboardButtonProps={{ 'aria-label': 'Fecha' }}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            <Grid item xs={11} sm={4} lg={4} className="fixedBottomOnlyMobile z-index-high">
                <Button
                    className="nextFormButtonMobile"
                    variant="contained"
                    color="primary"
                    onClick={handleComplete}
                    disabled={((!manualCuit || !manualFirstName || !manualLastName || !birthDate) && cuits.length < 1) || !cuit}>
                    CONTINUAR
                </Button>
            </Grid>
        </Box>
    );
};
