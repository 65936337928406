import React, { useEffect } from 'react';
import useCurrentTask from '../components/bpm/useCurrentTask';
import '../styles/components/error.scss';
import MacroPage from './shared/MacroPage';
import eventTrackingService from '../services/eventtracking.service';
import { ShowValidationError } from '../components/documentsUpload/DocumentUploaderCommon';

export const RestartManualDocumentsUpload = (props) => {
    const { hashKey } = props.match.params;
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);
    const uploadMethod = 'manual';

    useEffect(() => {
        eventTrackingService.trackEvent('Barcode_not_found', uploadMethod, null, 6);
    });

    const handleRetry = async (event) => {
        event.preventDefault();
        try {
            await task.completeTask();
        } catch (error) {
            if (error.response) {
                console.error('Error: ' + error);
            }
        }
    };

    const returnDesktopOrMobile = <MacroPage>{ShowValidationError(handleRetry, false)}</MacroPage>;

    return <>{returnDesktopOrMobile}</>;
};
