import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Tabs from '../components/login/tabs';
import '../styles/components/login.scss';
import MacroPage from './shared/MacroPage';

export const Login = (props) => {
    const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({
            width: null,
            height: null,
        });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener('resize', handleResize);
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }, []);
        return windowSize;
    };

    const size = useWindowSize();
    const breakpoint = 960;

    return (
        <>
            <MacroPage hideWhatsAppComponent>
                <Grid container>
                    {size?.width < breakpoint && <Box className="assetBg" />}
                    <Grid item xs={12}>
                        <p className="fs-20 fw-400 color-secondary mt-3">Tu préstamo personal 100% online</p>
                        <h3 className="subtitleLogin">Ingresá tus datos para ver la oferta que tenemos para vos</h3>
                        <Tabs {...props} className="" />
                    </Grid>
                    <Grid item xs={10} md={12} className="footer">
                        BANCO MACRO S.A. CUIT 30-50001008-4-AV. EDUARDO MADERO N°1182, CABA
                    </Grid>
                </Grid>
            </MacroPage>
        </>
    );
};
