import React, { useState, useEffect } from 'react';
import { UNDEFINED_VALUE } from './userInputConstants';
import Map from '../googleMap/Map';
import workflowService from '../../services/workflow.service';
import { GOOGLE_MAP_API_KEY, getEnv } from '../../env';
import { Grid, Box, MenuItem, Button } from '@material-ui/core';
import '../../styles/components/userInputs.scss';
import { CustomSelect } from '../customInputs/CustomSelect';

const MapUserInput = (props) => {
    const { conditioningValue, hashKey, stepName, userInput, onChange } = props;
    const [markerList, setMarkerList] = useState(userInput.userInputOptions.comboData);
    const [value, setValue] = useState(UNDEFINED_VALUE);
    const mapURL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=' + getEnv(GOOGLE_MAP_API_KEY);
    const [showMap, setShowMap] = useState(true);

    const [description, setDescription] = useState(null);

    useEffect(() => {
        if (value) {
            onChange(userInput, value);
        }
    }, [value, markerList]);

    
    useEffect(() => {
        

        async function getComboData() {
            try {
                let comboData = (await workflowService.getUserInputComboData(hashKey, userInput.name, stepName, conditioningValue)).data;
                setMarkerList(comboData);
                
            } catch (error) {
                console.error(error);
            }
        }
        if (conditioningValue) {
            getComboData();
        }
    }, [conditioningValue]);

    const handleMarkerChange = (userInput, selectedMarker) => {
        if(selectedMarker !== null) {
            onChange(userInput, selectedMarker.id);
        } else {
            onChange(userInput, null);
        }
        
        if(selectedMarker && selectedMarker !== null) {   
            setValue(selectedMarker.id);
            setDescription(selectedMarker.descripcion);
        }
        
    };

    const handleComboChange = (event) => {
        setValue(event.target.value);
    };

    const handleShowMap = () => {
        setValue(null);
        setShowMap(true);
    };

    const handleShowList = () => {
        setValue(null);
        setShowMap(false);
    };

    const getColorMap = () => {
        return showMap ? "primary" : "grey";
    };

    const getColorList = () => {
        return !showMap ? "primary" : "grey";
    };
    

    return (
        <Grid container className="mt-1">
            <Grid item xs={12} sm={12}>
            <p className="fw-400 color-dark-gray">{userInput.userInputOptions.label}</p>
            </Grid>
            <Grid item xs={12} sm={12}>
            
            
                <div className="mt-1">
                    <Button onClick={handleShowMap} className="width-50-per" variant="contained" color={getColorMap()}>
                        Mapa
                    </Button>
                    <Button onClick={handleShowList} className="width-50-per" variant="contained" color={getColorList()}>
                        Listado
                    </Button>
                </div>
                <Box className="mt-1"></Box>
            {showMap ?
                <>
                <Map googleMapURL={mapURL} 
                    onChange={handleMarkerChange}
                    markerList={markerList}
                    userInput={userInput}
                    containerElement={
                        <div style={{height: '300px', width: '300px'}} />
                    }
                    mapElement={
                        <div style={{height: '300px', width: '300px'}} />
                    }
                    loadingElement={<p>Cargando...</p>} 
                />
                <Grid item xs={12}>
                <Box className="mt-2"> </Box>
                    <div className="selecteddiv flex center ">
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            
                            <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
                                
                                {value ?
                                    <><p class="fw-500 color-dark-gray lh-160">
                                        Sucursal seleccionada
                                    </p>
                                    <p class="fw-400 color-dark-gray lh-160">
                                        {description}
                                    </p></>
                                :
                                    <p class="fw-500 color-dark-gray lh-160">
                                        Seleccioná una sucursal en el mapa para poder continuar
                                    </p>
                                }

                            </Grid>
                        </Grid>
                    </div>

                    
                </Grid>
                </>
            :
            
                <CustomSelect
                    fullWidth={true}
                    required={userInput.userInputOptions.required}
                    name={userInput.name}
                    label={userInput.userInputOptions.label}
                    onChange={handleComboChange}
                    value={value}
                    disabled={false}
                    errorHelperText="Requerido"
                    >
                    {markerList.map((item) => (
                        <MenuItem id={item.id} key={item.id} value={item.id}>
                            {item.descripcion}
                        </MenuItem>
                    ))}
                    {markerList.length > 1 && <MenuItem value={UNDEFINED_VALUE}>Seleccione alguna de las opciones</MenuItem>}
                </CustomSelect>
            
            }
            
            
            </Grid>
            

            
            
        </Grid>
        
    );
};

export default MapUserInput;
