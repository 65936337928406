import React, { useEffect, useState } from 'react';
import identityService from '../services/identity.service';
import { QuestionSlider } from '../components/identityVerification/QuestionSlider';
import useCurrentTask from '../components/bpm/useCurrentTask';
import '../styles/components/IdentifyVerification.scss';
import MacroPage from './shared/MacroPage';
import { Grid } from '@material-ui/core';

export const IdentityVerification = (props) => {
    const { hashKey } = props.match.params;
    const [questionnaireBatch, setQuestionnaireBatch] = useState(null);
    const [questionnaireId, setQuestionnaireId] = useState(null);
    const [questions, setQuestions] = useState(null);
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);

    useEffect(() => {
        async function getIdentityVerificationQuestions() {
            try {
                let validationData = (await identityService.getVerificationQuestions(hashKey)).data;
                setQuestions(validationData.questions);
                setQuestionnaireBatch(validationData.lote);
                setQuestionnaireId(validationData.questionary);
            } catch (error) {
                console.log(error.response.data);
            }
        }

        getIdentityVerificationQuestions();
    }, [hashKey]);

    const handleQuestionChange = (changedQuestion) => {
        // Using the useState setter with a function argument (which gives us the previous state)
        // to avoid a race condition when different questions change at the same time (which would
        // result in changes being ignored).
        setQuestions((previousQuestions) => {
            const changedQuestions = [];
            previousQuestions.forEach((question) => {
                if (question.questionId === changedQuestion.questionId) {
                    changedQuestions.push(changedQuestion);
                } else {
                    changedQuestions.push(question);
                }
            });
            return changedQuestions;
        });
    };

    const handleQuestionsAnswered = () => {
        const evaluateAnswers = async () => {
            const validationData = {
                lote: questionnaireBatch,
                questionary: questionnaireId,
                questions: [...questions],
            };
            try {
                await identityService.evaluateIdValidationAnswers(hashKey, validationData);
                await task.completeTask();
            } catch (error) {
                console.log(error.response.data);
            }
        };
        evaluateAnswers();
    };

    return (
        <MacroPage>
            <Grid container direction="row" justifyContent="center" alignItems="center" lg={12} sm={12} xs={12}>
                <Grid item lg={1}></Grid>

                <Grid container direction="column" justifyContent="center" alignItems="left" lg={10} sm={12} xs={12}>
                    <h3 className="color-primary mt-5 fw-500 d-h1">Tu préstamo personal 100% online</h3>

                    <p className=" mt-3 fw-200 d-h3">Una financiación a la medida de tus necesidades y metas.</p>
                    <div className="mt-3"></div>
                    <QuestionSlider questions={questions} onQuestionChange={handleQuestionChange} onQuestionsAnswered={handleQuestionsAnswered} />
                </Grid>
                <Grid item lg={1}></Grid>
            </Grid>
        </MacroPage>
    );
};
