import React from 'react';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import '../styles/components/documentsUpload.scss';
import imgDNI from './../assets/previewFrontDni.svg';
import imgDniBack from './../assets/previewBackDni.svg';
import imgSelfie from './../assets/previewSelfie.svg';
import HelperPopup from '../components/login/helperPopup';

export const DocumentsUpload = (props) => {
    const { onHandleNext } = props;

    const handleNext = () => {
        onHandleNext(false);
    };

    const helperInfoPopup = (
        <HelperPopup helperText="¿Por qué pedimos tus fotos?" dialogTitle="¿Por qué pedimos tus fotos?" dialogAction="Entendido">
            <Typography gutterBottom>
                Por tu seguridad, necesitamos que saques fotos de tu DNI y rostro para verificar que sos vos quien está solicitando el préstamo
            </Typography>
        </HelperPopup>
    );

    return (
        <>
            <Grid item container style={{ zIndex: 100 }} xs={12} direction="row" justify="center">
                <Grid item xs={12} md={6}>
                    <p className="mt-2 fs-14 fw-500">Vas a necesitar: </p>
                </Grid>
                <Grid item xs={12} md={6} justify="center">
                    <p className="center mt-2 fs-14 fw-400">Sacarle una foto al frente y dorso de tu DNI</p>
                </Grid>
                <Grid item xs={12} md={6} justify="center">
                    <div className="center mt-2">
                        <img src={imgDNI} alt="" style={{ paddingRight: '20px' }} />
                        <img src={imgDniBack} alt="" style={{ paddingLeft: '20px' }} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Box m={3} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <p className="center mt-2 fs-14 fw-400">Y hacer una prueba de vida</p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="center mt-2">
                        <img src={imgSelfie} alt="" />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Box m={3} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <p className="text-align-center mt-2 fs-14 fw-400">
                        Las fotos y datos son para uso exclusivo del banco y no se comparten con terceros.{' '}
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <Box m={3} />
                </Grid>
                <Grid item xs={12}>
                    {helperInfoPopup}
                </Grid>
            </Grid>
            <Grid item container className="validationIdentityButton">
                <Button onClick={handleNext} variant="contained" color="primary" className="width-100-per width-90-res">
                    Comenzar
                </Button>
            </Grid>
        </>
    );
};
