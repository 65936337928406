import { Grid } from '@material-ui/core';
import React from 'react';
import '../../styles/components/offerTerms.scss';
import { numberToPercentageText } from '../utils/numberUtils';

export const OfferTerms = (props) => {
    const { offer } = props;

    if (offer) {
        return (
            <Grid container direction="row">
                <Grid item xs={4} sm={3} md={2}>
                    <p className="fs-14 color-dark-gray mt-05">TNA: {numberToPercentageText(offer.nominalAnnualRate, 2)} </p>
                </Grid>
                <Grid item xs={4} sm={3} md={2}>
                    <p className="fs-14 color-dark-gray mt-05">TEA: {numberToPercentageText(offer.effectiveAnnualRate, 2)}</p>
                </Grid>
                <Grid item xs={4} sm={3} md={2}>
                    <p className="fs-17 fw-bold color-dark-gray mt-05">CFTEA: {numberToPercentageText(offer.totalFinancialCost, 2)}</p>
                </Grid>
            </Grid>
        );
    }
    return null;
};
