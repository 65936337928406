export const API_URL = 'API_URL';
export const LOCAL_URL = 'LOCAL_URL';
export const GTM_CODE = 'GTM_CODE';
export const TOC_AUTOCAPTURE_LIB = 'TOC_AUTOCAPTURE_LIB';
export const TOC_LIVENESS_LIB = 'TOC_LIVENESS_LIB';
export const TOC_HTTP = 'TOC_HTTP';
export const TOC_PRD = 'TOC_PRD';
export const ENCRYPT_MESSAGES = 'ENCRYPT_MESSAGES';
export const ENCRYPT_KEY = 'ENCRYPT_KEY';
export const GOOGLE_RECAPTCHA_KEY = 'GOOGLE_RECAPTCHA_KEY';
export const GOOGLE_MAP_API_KEY = 'GOOGLE_MAP_API_KEY';
export const URL_4ID = 'URL_4ID';
export const SAME_DEVICE_ONBOARDING_MINIMUM_DAYS = 'SAME_DEVICE_ONBOARDING_MINIMUM_DAYS';
export const SALESFORCE_BEACON_SCRIPT_URL = 'SALESFORCE_BEACON_SCRIPT_URL';
export const WHATSAPP_AVALAIBLE = 'WHATSAPP_AVALAIBLE';
export const WHATSAPP_DAYS_AVALAIBLE = 'WHATSAPP_DAYS_AVALAIBLE';
export const WHATSAPP_START_TIME_AVALAIBLE = 'WHATSAPP_START_TIME_AVALAIBLE';
export const WHATSAPP_END_TIME_AVALAIBLE = 'WHATSAPP_END_TIME_AVALAIBLE';
export const WHATSAPP_LOCAL_PHONE_NUMBER = 'WHATSAPP_LOCAL_PHONE_NUMBER';
export const WHATSAPP_TIME_BEFORE_SHOW_SEGS = 'WHATSAPP_TIME_BEFORE_SHOW_SEGS';
export const WHATSAPP_TEXT_MESSAGE = 'WHATSAPP_TEXT_MESSAGE';
export const HOTJAR_ENABLED = 'HOTJAR_ENABLED';

export const getEnv = (key) => window.env[key];
