const { parseISO } = require('date-fns');
const { utcToZonedTime, format } = require( 'date-fns-tz');

export const calculateAge = (birth) => {
    let response;
    if (birth) {
        const birthDate = new Date(birth);
        const actualDate = new Date();
        const ageDate = new Date(actualDate - birthDate);
        // getTime() returns the number of milliseconds since January 1, 1970 00:00:00.
        response = Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    return response
}

export const convertDateFormat = (inputDateStr) => {

    const parsedDate = parseISO(inputDateStr); 
    const timeZone = 'America/Argentina/Buenos_Aires';
    const zonedDate = utcToZonedTime(parsedDate, timeZone);
    const formattedDate = format(zonedDate, "yyyy-MM-dd HH:mm:ssXXX");
    return formattedDate;
  }
