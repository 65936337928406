import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const Disclaimer = (props) => {
    const classes = useStyles();

    return (
        <>
            <div className={`mt-3 ${props.showDivider ? 'divider' : ''}`}></div>
            <Grid item container xs={12} className="mt-2">
                <p className={classes.disclaimerText}>
                    Cartera de Consumo. Préstamos Personales en pesos a tasa fija. Sistema de amortización Francés. Esta simulación no implica oferta
                    de crédito, ni aceptación de solicitud, ni aprobación crediticia por parte de Banco Macro S.A. El resultado de la simulación es
                    meramente informativo y orientativo. El efectivo otorgamiento del crédito y su monto, se encuentra sujetos a aprobación crediticia
                    y condiciones de contratación de Banco Macro S.A. y al cumplimiento por parte del solicitante de la totalidad de las condiciones
                    comerciales y crediticias establecidas.
                </p>
                <div className="mt-2"></div>
                <p className={classes.disclaimerText}>
                    El monto máximo para Cartera General es de hasta $1.000.000 y para la Cartera Selecta es de hasta $2.000.000. El CFT incluye
                    intereses e IVA. No incluye impuesto de sellos en caso de corresponder. CFTA: Costo Financiero Total en TEA. TNA: Tasa Nominal Anual. TEA:
                    Tasa Efectiva Anual. IVA: Impuesto al Valor Agregado
                </p>
            </Grid>
        </>
    );
};

const useStyles = makeStyles(() => ({
    disclaimerText: {
        fontSize: '12px',
        color: '#000000',
        textAlign: 'justify',
        opacity: 0.7,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
    },
}));

export default Disclaimer;
