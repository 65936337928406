import axiosInstance from './axios.config';

const identityService = {
    getVerificationQuestions: (hashKey) => {
        return axiosInstance.get(`/v1/workflow/loanRequest/${hashKey}/idValidation/questions`);
    },
    evaluateIdValidationAnswers: (hashKey, validationData) => {
        return axiosInstance.post(`/v1/workflow/loanRequest/${hashKey}/idValidation/questions`, validationData);
    }
};

export default identityService;