import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme } from '@material-ui/core/styles'

const dropdownIconNode = <ExpandMoreIcon style={{ color: '#4a4a4a' }} />;

const dropdownIconComponent = () => {
    return dropdownIconNode;
};

const Theme = createTheme({
    palette: {
        primary: {
            main: '#092f57',
        },
        secondary: {
            main: '#092f57',
        },
        terms: {
            main: '#00000',
        },
    },
    button: {
        height: '300px',
    },
    props: {
        MuiSelect: {
            IconComponent: dropdownIconComponent,
        },
        MuiAutocomplete: {
            popupIcon: dropdownIconNode,
        },
    },
});

export default Theme;
