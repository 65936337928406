import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch, Grid } from '@material-ui/core';
import { FALSE_VALUE, TRUE_VALUE } from './userInputConstants';


const CheckUserInput = (props) => {
    const { userInput, onChange } = props;
    const [value, setValue] = useState(userInput.userInputOptions.defaultValue || FALSE_VALUE);

    useEffect(() => {
        onChange(userInput, value);
    }, [value]);

    const onSwitchChanged = (event) => {
        setValue(event.target.checked.toString());
    };

    return (
        <Grid item container direction="row" alignItems="center" xs={12} className="">
            <Grid item xs={7} className="">
                <p className="fs-16 color-dark-gray fw-500"> {userInput.userInputOptions.label} </p>
            </Grid>
            <Grid item container direction="row" alignItems="center" justifyContent="center" xs={5} className="">
                <Grid item container xs={4} justifyContent="flex-end" className={value === TRUE_VALUE ? 'thumbUnchecked' : 'thumbChecked'}>
                    No
                </Grid>
                <Grid item container xs={4} justifyContent="center">
                    <FormControlLabel
                        value={value}
                        control={<Switch name={userInput.name} color="primary" onChange={onSwitchChanged} checked={value === TRUE_VALUE} />}
                        labelPlacement="top"
                    />
                </Grid>
                <Grid item container xs={4} className={value === TRUE_VALUE ? 'thumbChecked' : 'thumbUnchecked'}>
                    Si
                </Grid>
            </Grid>
            {userInput.userInputOptions.hint && (
                <Grid item xs={12} className="">
                    <p className="fs-15 color-dark-gray fw-300 mt-1 mb-1"> {userInput.userInputOptions.hint} </p>
                </Grid>
            )}
        </Grid>
    );
};

export default CheckUserInput;
