import axios from 'axios';
import { LOCAL_URL, getEnv } from '../env';

// In this file we can create common headers and add interceptors

const axiosLocalInstance = axios.create({
    baseURL: `${getEnv(LOCAL_URL)}`,
    timeout: 30000,
    withCredentials: false,
});

export default axiosLocalInstance;
