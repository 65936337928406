import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Grid, Box } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Hidden from '@material-ui/core/Hidden';
import UserInput from './UserInput';
import normalizationService from '../../services/normalization.service';
import workflowService from '../../services/workflow.service';
import '../../styles/components/userInputs.scss';
import Loader from '../loader/Loader';
import { VARIABLE_NAMES, NORMALIZATION_STATUS } from './userInputConstants';

const CustomerDataUserInputs = forwardRef((props, ref) => {
    const { hashKey, stepName, sameLineIndexes } = props;
    const [inputGroups, setInputGroups] = useState([]);
    const [inputGroupError, setInputGroupError] = useState(null);
    const [userInputFilled, setUserInputFilled] = useState({});
    const [conditioningValues, setConditioningValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [requiresAddressNormalization, setRequiresAddressNormalization] = useState(false);
    const [normalizedAddress, setNormalizedAddress] = useState(null);
    const [normalizedAddressAlternatives, setNormalizedAddressAlternatives] = useState(null);
    const [normalizedAddressAlternativeIndex, setNormalizedAddressAlternativeIndex] = useState(0);
    const [submitRequested, setSubmitRequested] = useState(false);

    const sort = (groups) => {
        let normalizeAddressGroupSet = false;
        groups.forEach((group) => {
            switch (group.name.toString().toLowerCase()) {
                case 'datos personales':
                    group.index = 1;
                    group.name = '';
                    break;
                case 'datos de contacto':
                    group.index = 2;
                    break;
                case 'domicilio':
                case 'datos domicilio':
                    group.index = 3;
                    break;
                case 'declaración jurada':
                    group.index = 4;
                    break;
                case 'apertura de cuenta':
                    group.index = 5;
                    break;
                default:
                    group.index = 100;
            }
            if (group.name.toLowerCase().includes('domicilio')) {
                if (!normalizeAddressGroupSet) {
                    group.normalizeAddress = true;
                    setRequiresAddressNormalization(true);
                }
                normalizeAddressGroupSet = true;
            }
        });
        groups.sort((g1, g2) => {
            if (g1.index > g2.index) return 1;
            if (g2.index > g1.index) return -1;

            return 0;
        });
        return groups;
    };

    useEffect(() => {
        async function getUserInputFields() {
            try {
                setLoading(true);
                const userInputs = (await workflowService.getUserInputFields(hashKey, stepName)).data;
                setInputGroups(sort(userInputs.groups));
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        if (hashKey) {
            getUserInputFields();
        }
    }, [hashKey]);

    useEffect(() => {
        const validarForm = async () => {
            props.onComplete(userInputFilled);
            if (!submitRequested) {
                return;
            }
            if (!requiresAddressNormalization || !normalizedAddress) {
                props.onFormSubmitted(userInputFilled);
                setSubmitRequested(false);
            } else if (normalizedAddress) {
                props.onFormSubmitted(normalizedAddressToUserInputFilled(userInputFilled, normalizedAddress));
                setSubmitRequested(false);
            }
        };

        validarForm();
    }, [userInputFilled, submitRequested, normalizedAddress]);

    useEffect(() => {
        async function getUserInputFields() {
            try {
                setLoading(true);
                const userInputs = (await workflowService.getUserInputFields(hashKey, stepName)).data;
                setInputGroups(sort(userInputs.groups));
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        if (hashKey) {
            getUserInputFields();
        }
    }, [hashKey]);

    useImperativeHandle(ref, () => ({
        handleFormSubmission: async () => {
            if (normalizedAddressAlternatives) {
                const address = { ...normalizedAddressAlternatives[normalizedAddressAlternativeIndex] };
                setNormalizedAddressAlternatives(null);
                selectNormalizedAddress(address);
            } else if (requiresAddressNormalization) {
                setInputGroupError(null);
                const address = {
                    streetName: userInputFilled[VARIABLE_NAMES.ADDRESS_STREET_NAME],
                    streetNumber: userInputFilled[VARIABLE_NAMES.ADDRESS_STREET_NUMBER],
                    unitFloor: userInputFilled[VARIABLE_NAMES.ADDRESS_UNIT_FLOOR],
                    unitNumber: userInputFilled[VARIABLE_NAMES.ADDRESS_UNIT_NUMBER],
                    provinceCode: userInputFilled[VARIABLE_NAMES.ADDRESS_PROVINCE],
                    cityId: userInputFilled[VARIABLE_NAMES.ADDRESS_CITY],
                    postalCode: userInputFilled[VARIABLE_NAMES.ADDRESS_POSTAL_CODE],
                    trackingId: hashKey,
                };
                let normalizedAddressResponse = null;
                try {
                    setLoading(true);
                    normalizedAddressResponse = await normalizationService.normalizeAddress(address);
                } catch (error) {
                    if (!error.response.data || error.response.data.errorCode !== 'ADDRESS_NORMALIZATION_CITY_ID_NOT_FOUND' ) {
                        if (props.onError) {
                            props.onError({ message: 'Ocurrió un error al validar tu domicilio. Intentá nuevamente' });
                        }
                        throw error;
                    }
                } finally {
                    setLoading(false);
                }
                if(normalizedAddressResponse){
                    if (normalizedAddressResponse.data.status === NORMALIZATION_STATUS.ALTERNATIVES) {
                        setNormalizedAddressAlternatives(normalizedAddressResponse.data.alternativeAddresses);
                        throw {};
                    } else if (normalizedAddressResponse.data.status === NORMALIZATION_STATUS.OK) {
                        selectNormalizedAddress(normalizedAddressResponse.data);
                    } else {
                        // Leaving old code commented until we decide what to do.
                        //
                        // const error = {
                            //     inputGroupName: 'Domicilio',
                            //     title: 'No pudimos verificar este domicilio',
                            //     description: 'Ingresá una dirección válida',
                            // };
                            // setInputGroupError(error);
                            // throw error;
                    }
                }
            }
            setSubmitRequested(true);
        },
    }));

    const selectNormalizedAddress = (normalizedAddress) => {
        const newInputGroups = JSON.parse(JSON.stringify(inputGroups));
        newInputGroups.forEach((group, index) => {
            group.variables.forEach((userInput) => {
                if (userInput.name === VARIABLE_NAMES.ADDRESS_STREET_NAME) {
                    userInput.userInputOptions.defaultValue = normalizedAddress.streetName;
                }
                if (userInput.name === VARIABLE_NAMES.ADDRESS_STREET_NUMBER) {
                    userInput.userInputOptions.defaultValue = '' + normalizedAddress.streetNumber;
                }
                if (userInput.name === VARIABLE_NAMES.ADDRESS_PROVINCE) {
                    userInput.userInputOptions.defaultValue = normalizedAddress.provinceCode;
                    userInput.userInputOptions.inputsThatDependsOnMe.forEach((variableName) => {
                        setConditioningValues((previousConditioningValues) => {
                            return { ...previousConditioningValues, [variableName]: normalizedAddress.provinceCode };
                        });
                    });
                }
                if (userInput.name === VARIABLE_NAMES.ADDRESS_CITY) {
                    userInput.userInputOptions.defaultValue = normalizedAddress.cityCode;
                    userInput.userInputOptions.inputsThatDependsOnMe.forEach((variableName) => {
                        setConditioningValues((previousConditioningValues) => {
                            return { ...previousConditioningValues, [variableName]: normalizedAddress.cityCode };
                        });
                    });
                }
                if (userInput.name === VARIABLE_NAMES.ADDRESS_POSTAL_CODE) {
                    userInput.userInputOptions.defaultValue = '' + normalizedAddress.postalCode;
                }
            });
        });
        setUserInputFilled((previousState) => {
            return normalizedAddressToUserInputFilled(previousState, normalizedAddress);
        });
        setNormalizedAddress(normalizedAddress);
        setInputGroups(newInputGroups);
    };

    const normalizedAddressToUserInputFilled = (userInputFilled, normalizedAddress) => {
        return {
            ...userInputFilled,
            [VARIABLE_NAMES.ADDRESS_STREET_NAME]: normalizedAddress.streetName,
            [VARIABLE_NAMES.ADDRESS_STREET_NUMBER]: normalizedAddress.streetNumber,
            [VARIABLE_NAMES.ADDRESS_PROVINCE]: normalizedAddress.provinceCode,
            [VARIABLE_NAMES.ADDRESS_CITY]: normalizedAddress.cityCode,
            [VARIABLE_NAMES.ADDRESS_POSTAL_CODE]: normalizedAddress.postalCode,
            [VARIABLE_NAMES.ADDRESS_NEIGHBORHOOD]: normalizedAddress.neighborhood,
        };
    };

    const handleUserInputChange = (userInput, value) => {
        setUserInputFilled((previousState) => {
            return { ...previousState, [userInput.name]: value };
        });
        userInput.userInputOptions.inputsThatDependsOnMe.forEach((variableName) => {
            setConditioningValues((previousConditioningValues) => {
                return { ...previousConditioningValues, [variableName]: value };
            });
        });
    };

    const handleNormalizedAddressAlternativeChange = (event) => {
        setNormalizedAddressAlternativeIndex(Number(event.target.value));
    };

    return (
        <>
            <Loader loading={loading} />
            {inputGroups.map(
                (group, index) =>
                    normalizedAddressAlternatives &&
                    group.normalizeAddress && (
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <p className="fw-400 color-primary mt-3">¿Cuál es tu domicilio?</p>
                                <p className="fs-14 color-primary fw-400 mt-1 mb-2">Encontramos más de una dirección.</p>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={normalizedAddressAlternativeIndex}
                                        onChange={handleNormalizedAddressAlternativeChange}>
                                        {normalizedAddressAlternatives.map((address, index) => (
                                            <FormControlLabel
                                                className={`normalization-modal-form-group${
                                                    normalizedAddressAlternativeIndex === index ? '-selected' : ''
                                                }`}
                                                value={index}
                                                control={<Radio />}
                                                label={`${address.streetName} ${address.streetNumber},
                        ${address.neighborhood || ''} CP ${address.postalCode} - ${address.city} - ${address.province}`}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )
            )}
            {inputGroups.map((group, index) => (
                <Grid container xs={12} className="mt-1">
                    <Box key={index} style={{ width: '100%' }}>
                        <Hidden xsUp={normalizedAddressAlternatives} implementation="css">
                            <Grid container xs={12} direction="row" justifyContent="left" alignItems="left" className="">
                                <Grid item xs={12} className="">
                                    <p className="fw-500 fs-18 color-primary mt-1">{group.name}</p>
                                </Grid>
                                {inputGroupError && group.normalizeAddress && (
                                    <Grid item xs={12}>
                                        <div className="error-message mt-1 mb-1">
                                            <p className="fw-700 fs-14 color-dark-gray lh-160">{inputGroupError.title}</p>
                                            <p className="fw-500 fs-14 color-dark-gray lh-160">{inputGroupError.description}</p>
                                        </div>
                                    </Grid>
                                )}
                                <Grid container xs={12} className="">
                                    {group.variables.map((variable, index) => (
                                        <Grid item xs={sameLineIndexes && sameLineIndexes.some((v) => v === index) ? 6 : 12} sm={6}>
                                            <Box key={variable.name}>
                                                <Grid item xs={12} sm={11}>
                                                    <UserInput
                                                        hashKey={hashKey}
                                                        userInput={variable}
                                                        stepName={stepName}
                                                        onChange={handleUserInputChange}
                                                        conditioningValue={conditioningValues[variable.name]}
                                                        onAnalyticsEvent={props.onAnalyticsEvent}
                                                    />
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Box>
                </Grid>
            ))}
        </>
    );
});

export default CustomerDataUserInputs;
