import React, { useState } from 'react';
import { Button, Grid, Box } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import loanRequestService from '../services/loanRequest.service';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import ReactCodeInput from 'react-code-input';
import exclamationImage from './../assets/importantInfo.svg';
import Icon from '@material-ui/core/Icon';
import Loader from '../components/loader/Loader';

const AppPasswordCreationPage = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const [password, setPassword] = useState({ value: '' });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const { t } = useTranslation();

    const handlePasswordChange = (value) => {
        setPassword({ value: value });
    };

    const handlePasswordVisibilityButtonClick = async (event) => {
        event.preventDefault();
        setPasswordVisible(!passwordVisible);
    };

    const handleNextButonClick = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            await loanRequestService.update(hashKey, { macroAppTemporaryPassword: password.value });
            await task.completeTask();
        } catch (error) {
            let { errorCode } = error.response.data;
            if (errorCode !== 'INVALID_MACRO_APP_PASSWORD') {
                errorCode = 'UNKNOWN_ERROR';
            }
            setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`));
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    return (
        <MacroStepPage
            headerLead="Ya elegiste tu préstamo y cuotas"
            headerTitle="Generá tu clave digital"
            hashKey={hashKey}
            task={task}
            taskDefinitionKey={taskDefinitionKey}
            backToText={isMobile ? 'Completá tus datos' : undefined}
            hideStepper={true}
            stepNumber={3}
            totalSteps={4}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <>
                    <Loader loading={loading} />
                    <p className="fw-400 color-primary mt-3">Contraseña para app Macro</p>
                    <p className="fs-14 fw-400 mt-2">
                        Creá una clave temporal para ingresar por primera vez a tu App Macro y hacer uso de tu préstamo.
                    </p>
                    <Grid container xs={12} alignItems="center">
                        <ReactCodeInput
                            name="password"
                            fields={6}
                            style={{ marginTop: '20px' }}
                            onChange={handlePasswordChange}
                            value={password.value}
                            autoComplete="true"
                            filterChars="qwertyuiopasdfghjklñzxcvbnm, .-QWERTYUIOPASDFGHJKLÑZXCVBNM{}[]+´|@"
                            inputMode="numeric"
                            type={passwordVisible ? null : 'password'}
                        />{' '}
                        {passwordVisible && (
                            <Icon onClick={handlePasswordVisibilityButtonClick} style={{ marginLeft: '10px', marginTop: '20px' }}>
                                visibility_off
                            </Icon>
                        )}
                        {!passwordVisible && (
                            <Icon onClick={handlePasswordVisibilityButtonClick} style={{ marginLeft: '10px', marginTop: '20px' }}>
                                visibility
                            </Icon>
                        )}
                    </Grid>{' '}
                    <Grid container xs={12}>
                        <div className="box mt-2">
                            <img src={exclamationImage} width="35px" />
                            <div>
                                <p className="fs-14 fw-700">Recordá la clave</p>
                                <p className="fs-14 fw-400">La vas a tener que usar para ingresar por primera vez a la App Macro.</p>
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="fs-14 fw-700">Elección de contraseña:</p>
                            <ul className="mt-1">
                                <li>
                                    <p className="fs-14 fw-400">La clave debe tener 6 números</p>
                                </li>
                                <li>
                                    <p className="fs-14 fw-400">No debe poseer 3 o más números consecutivos ascendentes, descendentes o repetidos</p>
                                </li>
                                <li>
                                    <p className="fs-14 fw-400">No debe contener datos personales (tales como fecha de nacimiento, DNI)</p>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" className=" mt-2">
                        <Grid item xs={12} sm={8} md={6} lg={4} className="flex center">
                            <Box className="mt-4"></Box>
                            <div className="startButton center mb-20-web mt-20-web">
                                <Button
                                    className="width-100-per width-80-res"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNextButonClick}
                                    disabled={loading || !password.value || password.value.length < 6}>
                                    Siguiente
                                </Button>
                            </div>
                        </Grid>

                        <Grid item>
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </Grid>
                    </Grid>
                </>
            )}
        </MacroStepPage>
    );
};

export default AppPasswordCreationPage;
