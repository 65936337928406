import React from 'react';
import { useEffect, useState } from 'react';

const NpsScriptRunner = (props) => {
    const [interceptLoaded,setInterceptLoaded] = useState(false);
    const {user} =  props;
    

    const storePropertiesInSession = (map) => {
        for (const key in map) {
          const value = map[key];
          sessionStorage.setItem(key, value);
        }
      }


    useEffect(() => {
        if (user) {
           storePropertiesInSession(user);
        }        

    }, [user]);

    useEffect(() => {
        if (interceptLoaded) {
            window.QSI.API.run();
        }
    }, [interceptLoaded]);

    useEffect(() => {
   
        window.addEventListener("qsi_js_loaded", interceptLoaded, false);
        
        window.loadQualtricsApi();

        function onQSIApiLoaded() {
            setInterceptLoaded(true);
        }   

        function interceptLoaded() {
            window.QSI.API.unload();
            window.QSI.API.load().done(onQSIApiLoaded);
        };

        return () => {
            window.removeEventListener("qsi_js_loaded", interceptLoaded);
        };
    }, []);
    return <div id='ZN_39IJDn1AcaQhBC6'></div>;
};

export default NpsScriptRunner;
