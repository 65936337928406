import React, { useState, useEffect } from 'react';
import { FormControl, Radio, RadioGroup } from '@material-ui/core';
import { Grid, Box } from '@material-ui/core';

export const MultipleChoiceQuestion = (props) => {
    const { question } = props;
    const [selectedOptionId, setSelectedOptionId] = useState(null);

    useEffect(() => {
        const selectedOptionId = question.answerOptions[0].optionId;
        setSelectedOptionId(selectedOptionId);
        props.onChange({ ...question, selectedOptionId: selectedOptionId });
    }, [question.questionId]);

    const handleChange = (event) => {
        let newSelectedOptionId = parseInt(event.target.value);
        if (isNaN(newSelectedOptionId)) {
            newSelectedOptionId = '';
        }
        setSelectedOptionId(newSelectedOptionId);
        props.onChange({ ...question, selectedOptionId: newSelectedOptionId });
    };

    return (
        <>
            <FormControl component="fieldset">
                <p component="legend" className="fw-200 d-h5 color-darker-gray mb-4">
                    ¿ {question.text} ?
                </p>

                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    {question.answerOptions.map((answer) => (
                        <Grid container direction="row" alignItems="left">
                            <Grid item xs={2}>
                                <Radio
                                    color="primary"
                                    id={answer.optionId}
                                    value={answer.optionId}
                                    checked={selectedOptionId === answer.optionId}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Box className="mt-09"></Box>
                                <p className="fw-200 d-h5 color-darker-gray">{answer.text}</p>
                            </Grid>
                        </Grid>
                    ))}
                </RadioGroup>
            </FormControl>
        </>
    );
};
