import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: '999',
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        backgroundColor: 'black',
        opacity: '0.6',
    },
    circularProgress: {
        zIndex: '1000',
        position: 'fixed',
        top: '50vh',
        left: '45vw',
         
    },
}));

export default function Loader(props) {
    const classes = useStyles();
    if (props.loading) {
        return (
            <>
                <div className={classes.root}></div>
                <div className={classes.circularProgress}>
                    <CircularProgress />
                </div>
            </>
        );
    }
    return null;
}
