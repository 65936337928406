import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap, InfoWindow } from 'react-google-maps';
import { UNDEFINED_VALUE } from '../userInputs/userInputConstants';

const Map = ({ userInput, onChange, markerList, ...props }) => {


    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    const [defaultLat, setDefaultLat] = useState(UNDEFINED_VALUE);
    const [defaultLng, setDefaultLng] = useState(UNDEFINED_VALUE);

    useEffect(() => {
        if(markerList.length > 0) {
            setDefaultLat(markerList[0].aditionalData.lat);
            setDefaultLng(markerList[0].aditionalData.lng);
        }
    }, [markerList]);

    useEffect(() => {
        if (selectedMarker && selectedMarker !== UNDEFINED_VALUE) {
            onChange(userInput, selectedMarker);
        } else {
            onChange(userInput, null);
        }
    }, [selectedMarker]);

    return (
        <GoogleMap 
            defaultZoom={10}
            center={{ lat: (defaultLat ? defaultLat : 0), lng: (defaultLng ? defaultLng : 0)}}>
        
            
                {markerList.map((marker, i) =>{

                    return(
                        

                    <Marker
                        key={i}
                        position={{lat: marker.aditionalData.lat, lng: marker.aditionalData.lng}}
                        text="My Marker"
                        onClick={() => {
                            setSelectedMarker(marker);
                            setSelectedId(i);
                         }}>

                        
                        {selectedMarker && selectedId === i && (
                            <InfoWindow
                               onCloseClick={() => {
                                  setSelectedMarker(null);
                                  setSelectedId(null);
                               }}
                               
                            >
                                <span>{marker.descripcion}</span>
                            </InfoWindow>
                         )}
                    </Marker>

              )
            })}

                

              
            
        </GoogleMap>
    );
}

export default withScriptjs(
    withGoogleMap(
        Map
    )
)