import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React, { useState } from 'react';

const HelperPopup = ({ helperText, dialogTitle, children, dialogAction, dialogStyle, dialogTitleClassName, ...props }) => {
    const [openPopup, setOpenPopup] = useState(false);

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    return (
        <>
            <div className="helperTextPopupBox">
                <Button variant="text" onClick={handleClickOpenPopup} className="helpTextPopup fs-14" id="helpTextPopupId" style={{ padding: 0 }}>
                    {helperText}
                </Button>
            </div>
            <Dialog onClose={handleClosePopup} aria-labelledby="help-dialog-title" open={openPopup}>
                <div style={dialogStyle}>
                    <DialogTitle id="help-dialog-title" onClose={handleClosePopup}>
                        <span className={dialogTitleClassName}>{dialogTitle}</span>
                    </DialogTitle>
                    <DialogContent>{children}</DialogContent>
                    <DialogActions className="mb-2">
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center" item xs={10} sm={7}>
                                <Button variant="contained" onClick={handleClosePopup} color="primary" className="size-100">
                                    {dialogAction}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
};

export default HelperPopup;
