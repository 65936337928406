import React, { useEffect } from 'react';
import deviceCommunicationService from '../../services/deviceCommunication.service';
import workflowService from '../../services/workflow.service';
import { useHistory } from 'react-router-dom';
import loanRequestService from '../../services/loanRequest.service';
import Loader from '../loader/Loader';
import eventTrackingService from '../../services/eventtracking.service';

export const RedirectFromMinihash = (props) => {
    const { minHash, orig } = props.match.params;
    const history = useHistory();

    useEffect(() => {
        async function redirectFromMinihash() {
            try {
                let response = await deviceCommunicationService.getHashkeyFromMinihash(minHash);

                const accessMethod = orig ? 'qr' : 'sms';

                // seteamos el origen del acceso
                eventTrackingService.trackEvent('lectura qr', null, null, 4, accessMethod);

                let header = response.headers;
                localStorage.setItem('token', header.authorization);
                
                // persistimos
                const loanRequest = response.data;
                await loanRequestService.setDocumentUploadAccessMethod(loanRequest.hashKey, accessMethod);
                let minifiedUrlResponse = await deviceCommunicationService.getMinifiedUrl(minHash);
                const searchParams = new URLSearchParams(minifiedUrlResponse.data.urlParams);
                const currentTask = (await workflowService.getCurrentTask(loanRequest.hashKey)).data;
                const marketingSearchParams = new URLSearchParams(loanRequest.marketingData);
                for (let [key, val] of marketingSearchParams.entries()) {
                  searchParams.append(key, val);
                }
                history.push({
                    pathname: `/${currentTask.taskDefinitionKey}/${loanRequest.hashKey}`,
                    search: searchParams.toString(),
                });
            } catch (error) {
                history.push({ pathname: `/error`, search: history.location.search });
            }
        }

        redirectFromMinihash();
    }, [minHash]);

    return (
        <>
            <div>
                <Loader loading="true" />
            </div>
        </>
    );
};
export default RedirectFromMinihash;
