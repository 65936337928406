import React from 'react';
import { Grid, useTheme, useMediaQuery, Box, CardActionArea } from '@material-ui/core';
import NavBarSocialMedia from '../../components/navBar/NavBarSocialMedia';
import './../../styles/components/loanSuccessPage.scss';
import WhatsappComponent from '../../components/whatsapp/WhatsappComponent';

const WelcomePage  = (props) => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    const customMargin = smUp ? '15vh' : '10vh';

    return (
        <>
            <NavBarSocialMedia></NavBarSocialMedia>
            <Box className="assetBg"></Box>
            <Box style={{ marginTop: customMargin }}></Box>
            <Grid item container>
                <Grid item container xs={1} className=""></Grid>
                <Grid item container xs={10}>
                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <p className={`color-light-blue fw-400 fs-${smUp ? '24' : '18'}`}>{props.headerLead}</p>
                            <Box className="mt-1"></Box>
                            <p className={`color-primary fs-${smUp ? '38 fw-500' : '22 fw-700'}`}>{props.headerTitle}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="mt-1 "></Box>
                            <p className={`mb-3 color-primary fs-${smUp ? '38 fw-500' : '22 fw-700'}`}>{props.headerSubtitle}</p>
                        </Grid>

                        <Grid item container xs={12} sm={10} md={6} className="" >
                            {props.children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <WhatsappComponent />
            <div className="mt-4-landscape"></div>
        </>
    );
};

export default WelcomePage;
