import React, { useState, useEffect } from 'react';
import UserInput from './UserInput';
import workflowService from './../../services/workflow.service';
import '../../styles/components/userInputs.scss';
import logoexl from '../../assets/exclamacion.svg';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import installmentIcon from '../../assets/installment-icon.svg';
import accountIcon from '../../assets/account-icon.svg'

const UserInputs = (props) => {
    const { hashKey, stepName } = props;
    const [inputGroups, setInputGroups] = useState([]);
    const [userInputFilled, setUserInputFilled] = useState({});
    const [conditioningValues, setConditioningValues] = useState({});

    useEffect(() => {
        async function getUserInputFields() {
            try {
                const userInputs = (await workflowService.getUserInputFields(hashKey, stepName)).data;

                setInputGroups(userInputs.groups);
            } catch (error) {
                console.error(error.response);
            }
        }

        if (hashKey) {
            getUserInputFields();
        }
    }, [hashKey]);

    useEffect(() => {
        const validarForm = async () => {
            props.onComplete(userInputFilled);
        };

        validarForm();
    }, [userInputFilled]);

    const handleUserInputChange = (userInput, value) => {
        setUserInputFilled((previousState) => {
            return { ...previousState, [userInput.name]: value };
        });
        userInput.userInputOptions.inputsThatDependsOnMe.forEach((variableName) => {
            setConditioningValues((previousConditioningValues) => {
                return { ...previousConditioningValues, [variableName]: value };
            });
        });
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const logo = matches ? <img className="logoAdm" src={logoexl} alt="" /> : null;
    return (
        <>
            {inputGroups.map((group, index) => (
                <Grid item container xs={12} className="mt-3 ">
                    <Box key={index} style={{ width: '100%' }}>
                        <Grid item container xs={12} direction="row" justifyContent="left" alignItems="left" className="">
                            <Grid item xs={12} className="">
                                <p className="fw-500 fs-16 color-primary mb-2">{group.name}</p>
                            </Grid>

                            <Grid item container xs={12}>
                                <div className="importantDiv flex center mb-2">
                                    <Grid item container direction="row" justify="center" alignItems="center">
                                        <Grid item container lg={12} sm={12} md={12} xl={12} xs={12}>
                                            <Grid item container lg={1} sm={3} md={3} xl={1} xs={3} justifyContent="center">
                                                <img src={accountIcon} alt="" width="40" height="auto" />
                                            </Grid>
                                            <Grid item container lg={11} sm={9} md={9} xl={11} xs={9} style={{ padding: '5px 15px 5px 0' }}>
                                                <p className="fw-400 fs-14 lh-20 color-dark-gray">
                                                Te vamos abrir una caja de ahorro <b>100% gratuita</b> en dónde se va depositar tu préstamo.
                                                </p>
                                            </Grid>
                                        </Grid>
                                        <Grid item container lg={12} sm={12} md={12} xl={12} xs={12} style={{marginTop: '10px'}}>
                                            <Grid item container lg={1} sm={3} md={3} xl={1} xs={3} justifyContent="center">
                                                <img src={installmentIcon} alt="" width="35" height="auto" />
                                            </Grid>
                                            <Grid item container lg={11} sm={9} md={9} xl={11} xs={9} style={{ padding: '5px 15px 5px 0' }}>
                                                <p className="fw-400 fs-14 lh-20 color-dark-gray">
                                                <b>Las cuotas se debitarán automáticamente</b> de tu cuenta. 
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className="mt-2"></div>
                                <p className="fw-400 color-dark-gray">Seleccioná la sucursal Macro que te quede más comodo</p>
                                <div className="mt-2"></div>
                            </Grid>
                            <Grid container xs={12} className="mb-4">
                                {group.variables.map((variable) => (
                                    <Grid item xs={12} sm={6}>
                                        <Box key={variable.name}>
                                            <Grid item xs={12} sm={11}>
                                                <Box className="mt-1"></Box>
                                                <UserInput
                                                    hashKey={hashKey}
                                                    userInput={variable}
                                                    stepName={stepName}
                                                    onChange={handleUserInputChange}
                                                    conditioningValue={conditioningValues[variable.name]}
                                                />
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))}


                                
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            ))}
        </>
    );
};

export default UserInputs;
