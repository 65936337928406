import React, { useState } from 'react';
import rejectedImageUrl from './../../assets/rejected.svg';
import MacroPage from './../shared/MacroPage';
import { Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import MainButton from './../../components/MainButton';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import './../../styles/components/error.scss';
import './../../styles/components/rejectedPage.scss';

export const Rejected = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [rejectionCode, setRejectionCode] = useState(props.match.params.rejectedReasonCode);

    const getCallToActionParams = (rejectionCode) => {
        let params = null;
        switch (rejectionCode) {
            case 'REJECTED_BY_BESMART':
                params = {
                    translationPrefix: 'REJECTED.REJECTED_BY_BESMART.CALL_TO_ACTION',
                    backgroundClassName: 'accountOpeningCallToActionBackground',
                    backgroundClassNameMobile: 'backgroundStyleMobile accountOpeningCallToActionBackgroundMobile',
                };
                break;
            default:
                params = null;
        }
        return params;
    };

    const handleBack = (event) => {
        event.preventDefault();
        history.push({ pathname: `/customerIdentification`, search: history.location.search });
    };

    const rejectedImage = <img height="163px" src={rejectedImageUrl} alt="" className="mb-4"></img>;
    const rejectedImageMobile = <img height="134px" src={rejectedImageUrl} alt="" className="mb-4"></img>;

    return (
        <>
            <MacroPage callToAction={getCallToActionParams(rejectionCode)} hideWhatsAppComponent>
                <Grid container direction="column">
                    <Grid item xs={12} style={{ marginBottom: '40px' }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item container xs={12} direction="row" alignItems="center">
                                <Grid item container xs={0} lg={1} direction="column" className=""></Grid>
                                <Grid item container xs={12} lg={10} direction="column" className="">
                                    <Grid item xs={12} style={{ marginTop: '50px' }}>
                                        {smDown ? rejectedImageMobile : rejectedImage}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className="fs-24 fw-400 ls-33 color-primary">{t('REJECTED.' + rejectionCode + '.TITLE')}</p>
                                    </Grid>
                                    <Grid item className="mt-4" xs={12}>
                                        <Box className="mt-3"> </Box>
                                        <p className="fs-16 color-dark-gray fw-400">
                                            {t('REJECTED.' + rejectionCode + '.DESCRIPTION', 'Causa de Rechazo inesperada')}
                                        </p>
                                    </Grid>

                                    {!getCallToActionParams(rejectionCode) && !smDown && (
                                        <Grid item xs={12} sm={6}>
                                            <MainButton onClick={handleBack}>Intentar de nuevo</MainButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!getCallToActionParams(rejectionCode) && smDown && (
                    <Grid item xs={12}>
                        <div className="startButton center fixed-bottom-padding">
                            <Button className="flex center width-80-per" variant="contained" color="primary" onClick={handleBack}>
                                Intentar de nuevo
                            </Button>
                        </div>
                    </Grid>
                )}
            </MacroPage>
        </>
    );
};