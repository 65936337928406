import React from 'react';
import LoanRequestResultPage from './shared/LoanRequestResultPage';
import useLoanRequest from '../hooks/useLoanRequest';
import successImage from './../assets/success.svg';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { numberToCurrencyText } from './../components/utils/numberUtils';
import { useHistory, Link } from 'react-router-dom';
import Loader from '../components/loader/Loader';
import InfoBox from '../components/shared/InfoBox';
import { isMobile } from 'react-device-detect';
import ContinueMobile from '../components/deviceCommunication/continueMobile';

export const IdVerificationEvaluationPage = (props) => {
    const { hashKey } = props.match.params;
    const { loanRequest } = useLoanRequest(hashKey);
    const theme = useTheme();
    const history = useHistory();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleGoBackHomeClick = (event) => {
        event.preventDefault();
        history.push({ pathname: `/`, search: history.location.search });
    };

    if (!loanRequest) {
        return <Loader loading={true} />;
    }
    return (
        <LoanRequestResultPage
            headerLead={'¡Casi tenés el dinero en tu cuenta!'}
            headerTitle={`¡Felicitaciones ${loanRequest.customerName}!`}
            headerSubtitle="El Préstamo ya es casi tuyo"
            title="Estamos verificando tus datos y en momentos te informaremos el resultado"
            hideStepper={true}
            hideQuestions={true}
            resultImage={<img className="fit-container width-90-per" src={successImage} alt=""></img>}>
            {!isMobile ? (
            <ContinueMobile hashKey={hashKey}/>
            ) : (<>
            <Grid item container xs={12} sm={7} spacing={1}>
                <Grid item container xs={10}>
                    <Grid item xs={9}>
                        <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Monto a acreditar</p>
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        <p className={`fw-700 color-primary fs-1${smUp ? '6' : '5'}`}>{numberToCurrencyText(loanRequest.requestedAmount)}</p>
                    </Grid>
                </Grid>
                <Grid item container xs={10}>
                    <Grid item xs={9}>
                        <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Cuotas</p>
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>{loanRequest.installmentsNumberSelected}</p>
                    </Grid>
                </Grid>
                <Grid item container xs={10}>
                    <Grid item xs={9}>
                        <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>Monto de cada cuota</p>
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        <p className={`fw-400 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                            {numberToCurrencyText(loanRequest.installmentAmountSelected)}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container spacing={3} xs={12}>
                <Grid item>
                    <InfoBox className="mt-2">
                        <p className="fw-400">
                            Recordá que te abriremos una caja de ahorro <span className="fw-700">100% gratuita</span> en dónde se va depositar tu préstamo
                            y las cuotas se debitarán de la misma.{' '}
                        </p>
                    </InfoBox>
                </Grid>
                <Grid item>
                    <p className={`fw-700 color-dark-gray fs-1${smUp ? '6' : '5'}`}>
                        Te vamos a envíar un e-mail con todo lo que necesites saber sobre tu nueva cuenta.
                    </p>
                </Grid>
                <Grid item container spacing={3} className="mt-4">
                    <Grid item xs={12} md={8}>
                        <div className="center">
                            <Link href="#">DESCARGAR EL DETALLE DE CUOTAS</Link>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="center">
                            <Link href="/" onClick={handleGoBackHomeClick}>
                                VOLVER AL HOME
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Grid></>
            )};
        </LoanRequestResultPage>
    );
};
