import React from 'react';
import { Grid } from '@material-ui/core';
import './../../styles/components/loanSuccessPage.scss';
import hintLight from '../../assets/hint-light.svg';
import hintBlur from '../../assets/hint-blur.svg';
import hintCamera from '../../assets/hint-camera.svg';
import hintFace from '../../assets/hint-face.svg';
import hintWarning from '../../assets/hint-warning.svg';

const DocumentsUploadHints = (isSelfie = false) => {
    if (isSelfie) {
        return (
            <Grid container direction="column" justifyContent="center" alignItems="center" lg={12} sm={12} xs={12}>
                <Grid item xs={10}>
                    <ul className="hint-list">
                        <li>
                            <img src={hintLight} alt="" />
                            Buscá un lugar luminoso
                        </li>
                        <li>
                            <img src={hintFace} alt="" />
                            Despejá tu rostro
                        </li>
                        <li>
                            <img src={hintWarning} alt="" />
                            Ubicá tu rostro dentro de la máscara y seguí las indicaciones
                        </li>
                    </ul>
                </Grid>
            </Grid>
        );
    }
    return (
        <div>
            <ul className="hint-list">
                <li>
                    <img src={hintLight} alt="" />
                    Buscá un lugar bien iluminado
                </li>
                <li>
                    <img src={hintWarning} alt="" />
                    Utilizá tu último DNI vigente
                </li>
                <li>
                    <img src={hintBlur} alt="" />
                    Asegurate que los datos estén legibles
                </li>
                <li>
                    <img src={hintCamera} alt="" />
                    Aprobá los permisos de la cámara
                </li>
            </ul>
        </div>
    );
};

export default DocumentsUploadHints;
