import React, { useState, useEffect } from 'react';
import { UNDEFINED_VALUE } from './userInputConstants';
import { CustomTextField } from '../customInputs/CustomTextField';

const TextUserInput = (props) => {
    const { userInput, onChange } = props;
    const [value, setValue] = useState(UNDEFINED_VALUE);
    const [regex, setRegex] = useState(null);

    useEffect(() => {
        if (value) {
            onChange(userInput, value);
        }
    }, [value]);

    useEffect(() => {
        if (userInput) {
            if (userInput.userInputOptions.pattern) {
                setRegex(new RegExp(userInput.userInputOptions.pattern));
            }
            if (userInput.userInputOptions.defaultValue) {
                setValue(userInput.userInputOptions.defaultValue);
            }
        }
    }, [userInput]);

    const handleTextFieldChange = (event) => {
        const newValue = event.target.value;
        if (!regex || newValue.match(regex)) {
            setValue(newValue);
        }
    };

    return (
        <CustomTextField
            disabled={userInput.userInputOptions.disabled}
            fullWidth={true}
            required={userInput.userInputOptions.required}
            value={value}
            name={userInput.name}
            label={userInput.userInputOptions.label}
            onChange={handleTextFieldChange}
            errorHelperText="Requerido"
            helperText={userInput.userInputOptions.hint}
        />
    );
};

export default TextUserInput;
