import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Box } from '@material-ui/core';
import deviceCommunicationService from '../../services/deviceCommunication.service';
import '../../styles/components/documentsUpload.scss';
import phoneImage from '../../assets/celu.svg';

import Loader from '../loader/Loader';
import Qrcode from './qrcode';

import eventTrackingService from '../../services/eventtracking.service';

const MAX_WAIT_TIME = 60;

export const ContinueMobile = (props) => {
    const { hashKey, customerPhone } = props;
    const history = useHistory();
    const [validationCodeSentSms, setValidationCodeSentSms] = useState(false);
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);
    const [loading, setLoading] = useState(null);

    const handleSendSMSLink = () => {
        setLoading(true);
        eventTrackingService.trackEvent('enviar codigo sms pasaje mobile', null, null, 2);
        setValidationCodeSentSms(true);

        deviceCommunicationService
            .sendPicturesUploadFromMobileTextMessage(hashKey, { urlParams: history.location.search })
            .catch(function (error) {
                console.error(error);
                setLoading(false);
                setValidationCodeSentSms(false);
            });
        setLoading(false);
    };

    return (
        <>
            <Grid container className="mt-3 ">
                <Grid item container className="mt-4">
                    <Grid item container xs={12} sm={3} md={3}>
                        <div className="qrstyle">
                            <img src={phoneImage} alt=""></img>
                        </div>

                        <div className="center" style={{ paddingTop: 50 }}>
                            <Qrcode className="center " hashKey={hashKey} />
                        </div>
                    </Grid>
                    <Grid item container xs={12} sm={9} md={9}>
                        <Grid item container direction="column" justifyContent="center" alignItems="center">
                            <Grid item container>
                                <p className="fs-20 fw-400  ml-5 lh-160">
                                    Necesitamos validar tu identidad para que puedas continuar con tu solicitud.
                                    <br />
                                    Para esto será necesario que continúes el proceso desde tu celular.
                                </p>
                            </Grid>
                            <Box style={{ height: '30px' }}></Box>
                            <Grid item container>
                                <p className="fs-20 fw-400  ml-5" style={{ lineHeight: '30px' }}>
                                    Lo podrás realizar escaneando el QR o te enviaremos un sms al <b className="fw-500 ">{customerPhone} </b> con un
                                    link para que puedas seguir con los próximos pasos.
                                </p>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} direction="row" alignItems="center">
                            <Grid item container xs={3} md={4} lg={4} xl={3}></Grid>
                            <Loader loading={loading} />
                            <Grid container xs={6} md={4} lg={5} xl={4} className="mt-4">
                                <Button
                                    style={{ width: '100%', height: '40px' }}
                                    onClick={handleSendSMSLink}
                                    disabled={validationCodeSentSms}
                                    variant="contained"
                                    color="primary">
                                    Enviar SMS
                                </Button>
                                <i className="mt-1 fs-12 fw-400 fst-reg" hidden={!validationCodeSentSms}>
                                    Te enviamos un código por SMS
                                </i>
                                <Box>
                                    <i className="fs-12 fw-400 fst-reg" hidden={!validationCodeSentSms}>
                                        {' '}
                                        (Aguardá {seconds} segundos para reenviar)
                                    </i>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default ContinueMobile;
