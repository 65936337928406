import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import * as qs from 'qs';

import MacroStepPage from './shared/MacroStepPage';
import useLoanRequest from '../hooks/useLoanRequest';
import loanRequestService from '../services/loanRequest.service';
import eventTrackingService from '../services/eventtracking.service';
import useCurrentTask from '../components/bpm/useCurrentTask';
import Toaster from '../components/alerts/Toaster';
import Loader from '../components/loader/Loader';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import { CheckEmailValidation } from '../components/login/checkEmail/checkEmailValidation';

const ValidateEmailPage = (props) => {
    const { hashKey } = props.match.params;
    const { loanRequest, loadingLoanRequest } = useLoanRequest(hashKey);
    const [loading, setLoading] = useState(null);
    const [message, setMessage] = useState(null);
    const [open, setOpen] = useState(false);
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);
    const [taskLoaded, setTaskLoaded] = useState(false);
    const { t } = useTranslation();
    const [state, setState] = useState('email_input');
    const [isRetrying,setIsRetrying] = useState(false);

    useEffect(() => {
        if (task && task.getTaskDefinitionKey()) {
            setTaskLoaded(true);
        }
    }, [task]);

    useEffect(() => {
        const { pin } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        if (loanRequest && taskLoaded && pin) {
            completeTask(pin);
        }
    }, [loanRequest, taskLoaded]);

    const completeTask = async (pin) => {
        setState('email_verification_in_progress');
        setLoading(true);
        try {
            await loanRequestService.update(hashKey, { emailVerificationCode: pin });
            await task.completeTask({ state: { emailVerified: true } });
        } catch (error) {
            setIsRetrying(true);
            setState('email_retrying');
            const { errorCode } = error.response.data;
            var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
            setMessage(msg);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleVerificationCodeSent = () => {
        setState('email_verification');
        eventTrackingService.trackEvent('prestamos_envio_mail_validacion', null, null);
    };

    const handleVerificationReset = () => {
        setState('email_input');
    };

    const handleVerificationCodeError = (error) => {
        const { errorCode } = error.response.data;
        setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`));
        setOpen(true);
    };

    const handleVerificationPinSet = (pin) => {
        completeTask(pin);
    };

    return (
        <MacroStepPage
            headerLead={state === 'email_input' ? 'Ya elegiste tu préstamo y cuotas' : null}
            headerTitle={state === 'email_input' ? '¡Ya casi estás! Para terminar necesitamos validar tu dirección de email' : null}
            task={task}
            taskDefinitionKey={props.taskDefinitionKey}
            backToText="Volver"
            hideStepper={true}
            stepNumber={4}
            totalSteps={4}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <>
                    <Loader loading={loading || loadingLoanRequest} />
                    <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                    {state !== 'email_verification_in_progress' && (
                        <div className="width-100-per">
                            {loanRequest && (
                                <CheckEmailValidation
                                    defaultEmail={loanRequest.customerEmail}
                                    hashKey={hashKey}
                                    onError={handleVerificationCodeError}
                                    onReset={handleVerificationReset}
                                    isRetrying={isRetrying}
                                    onVerificationCodeSent={handleVerificationCodeSent}
                                    onVerificationPinSet={handleVerificationPinSet}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </MacroStepPage>
    );
};

export default ValidateEmailPage;
