export const numberToCurrencyText = (value, decimalPlaces = 0) => {
    if (!value) {
        return '';
    }
    let result = value
        .toLocaleString('en-US', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        })
        .replace(/\./g, '|')
        .replace(/\,/g, '.')
        .replace(/\|/g, ',');
    return '$' + result;
};

export const numberToPercentageText = (value, decimalPlaces = 2) => {
    if (!value) {
        return '';
    }
    return (
        value.toLocaleString('es-AR', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        }) + '%'
    );
};

export const currencyFormatToNumber = (value) => {
    return parseFloat(
        value
            .replace(/[^\d.-]/g, '')
            .replace(/ /g, '')
            .replace(/\$/g, '')
            .replace(/\./g, '')
            .replace(/,/, '.')
    );
};
