import axiosInstance from './axios.config';
import eventTrackingService from './eventtracking.service';

const RESTAPI_VERSION = '/v1';

const loanRequestService = {
    getLoanRequest: (hashKey) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}`).then((response) => {
            const isClient = response.data.cobisCustomerId !== null ? '1' : '0';
            eventTrackingService.setIsClient(isClient);
            eventTrackingService.setDniId(response.data.customerId);
            eventTrackingService.setTestingGroup(response.data.testingGroup);
            eventTrackingService.setCustomerSex(response.data.sex);
            return response;
        });
    },
    setSelectedProducts: (hashKey, selectedProduct) => {
        var dto = {
            productType: selectedProduct.selectedProductId,
            productValue: selectedProduct.installmentSelected.requestedAmount,
            productsOffer: selectedProduct.availableProducts,
        };
        return axiosInstance.put(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/setSelectedProducts`, dto);
    },
    setCustomerData: (hashKey, customerData) => {
        const dto = {
            hashkey: hashKey,
            customerDataMap: customerData,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/customerdata`, dto);
    },
    setCustomerPersonalAddressData: (hashKey, customerData) => {
        const dto = {
            hashkey: hashKey,
            customerDataMap: customerData,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/customerpersonaladdressdata`, dto);
    },
    setCustomerAffidavitData: (hashKey, customerData) => {
        const dto = {
            hashkey: hashKey,
            customerDataMap: customerData,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/customeraffidavitdata`, dto);
    },

    setOfficeDataForAccountOpening: (hashKey, customerData) => {
        const dto = {
            hashkey: hashKey,
            customerDataMap: customerData,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/officeDataForAccountOpening`, dto);
    },
    completeSignature: (hashKey) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/finishSelling`);
    },
    finishLoanRequestWithoutSign: (hashKey) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/finishLoanRequestWithoutSign`);
    },
    setProvinceCode: (hashKey, provinceCode) => {
        const loanRequestDto = {
            branchProvinceCode: provinceCode,
        };
        return axiosInstance.put(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/province`, loanRequestDto);
    },
    hasAnyAccount: (hashKey) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/hasAnyAccount`);
    },
    update: (hashKey, body) => {
        return axiosInstance.put(`${RESTAPI_VERSION}/loanrequests/${hashKey}`, body);
    },
    setCustomerAfipData: (hashKey, birthDate) => {
        const loanRequestDto = {
            birthDate: birthDate,
        };
        return axiosInstance.put(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/customerAfipData`, loanRequestDto);
    },
    setDocumentUploadAccessMethod: (hashKey, accessType) => {
        const loanRequestDto = {
            documentUploadAccessMethod: accessType,
        };
        return axiosInstance.put(`${RESTAPI_VERSION}/loanrequests/${hashKey}/documentUploadAccessMethod`, loanRequestDto);
    },
};

export default loanRequestService;
