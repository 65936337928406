import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/loader/Loader';
import WhatsappComponent from '../../components/whatsapp/WhatsappComponent';
import { SALESFORCE_BEACON_SCRIPT_URL, getEnv } from '../../env';
import '../../styles/components/shared/macroPage.scss';
import NavBarSocialMedia from './../../components/navBar/NavBarSocialMedia';
import CallToActionCard from './../../components/shared/CallToActionCard';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: null,
        height: null,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const MacroPage = (props) => {
    const { hideWhatsAppComponent } = props;
    const { t } = useTranslation();
    const size = useWindowSize();
    const width = size.width;
    const marginTopDevice = width > 600 ? '72px' : '50px';
    const backgroundClassName = props.callToAction?.backgroundClassName || 'backgroundStyle';
    const backgroundClassNameMobile = props.callToAction?.backgroundClassNameMobile || 'backgroundStyle';
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const showWhatsAppComponent = !hideWhatsAppComponent;

    ScrollToTop();

    return (
        <>
            <Helmet>{getEnv(SALESFORCE_BEACON_SCRIPT_URL) && <script type="text/javascript" src={getEnv(SALESFORCE_BEACON_SCRIPT_URL)} />}</Helmet>
            <Suspense fallback={<Loading />}>
                <Grid container direction="row" justifyContent="center">
                    <Grid item container>
                        <NavBarSocialMedia />
                        {!smDown && (
                            <Grid
                                height="100%"
                                item
                                md={6}
                                style={{ marginTop: `${marginTopDevice}`, minHeight: '92.2vh', overflow: 'hidden' }}
                                className={backgroundClassName}>
                                <Box>{props.callToAction && <CallToActionCard {...props.callToAction} />}</Box>
                            </Grid>
                        )}
                        <Grid item container justifyContent="center" md={!smDown ? 6 : 12} style={{ marginTop: marginTopDevice }}>
                            <Grid item xs={10}>
                                {props.children}
                            </Grid>
                        </Grid>
                        {props.callToAction && smDown && (
                            <Grid item xs={12} style={{ width: '100%' }}>
                                <Box className={backgroundClassNameMobile} style={{ minHeight: '90vh' }}>
                                    <CallToActionCard {...props.callToAction} />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {showWhatsAppComponent && <WhatsappComponent />}
            </Suspense>
        </>
    );
};

export default MacroPage;
